import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { encriptarParametrosUrl } from '@utils/encript.util';
import { DialogService } from '@services/dialog.service';
import { LoaderService } from '@services/loader.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private dialogService: DialogService,
    private loaderService: LoaderService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((e: HttpErrorResponse) => {
        let error = { titulo: '', mensaje: '' };

        // if (e.status === 404) {
        //   this.router.navigateByUrl('/error-page', {
        //     state: {
        //       statusCode: 404,
        //       descripcionError: 'No se encontro el recurso',
        //       mensaje: 'No se encontro el recurso',
        //       titulo: 'Not found',
        //     },
        //   });
        // }
        if (e.status == 500) {
          this.dialogService.showError(
            'Problemas en el servidor (500)',
            'Problemas en el servidor, por favor intentalo mas tarde'
          );
        }
        if (e.status == 0 || e.status == undefined) {
          this.dialogService.showError(
            'Error de conexión a la api',
            'Problemas en la conexión a la api, por favor intentelo nuevamente'
          );
        }
        if (e.status == 401) {
          this.loaderService.hide();
          let url =
            '/error?t=' +
            encriptarParametrosUrl('La sesión ha caducado') +
            '&m=' +
            encriptarParametrosUrl(
              'La sesión ha caducado, reingrese al sistema'
            );
          this.router.navigateByUrl(url);
        }

        this.loaderService.hide();
        return throwError(e);
      })
    );
  }
}
