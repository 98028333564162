import { Directive, Input, OnChanges, ElementRef } from '@angular/core';

@Directive({ selector: '[disableLink]' })
export class DisableLinkDirective implements OnChanges {
  @Input()
  disableLink: boolean = false;

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    this.disableLink ? this.disable() : this.enable();
  }

  private disable(): void {
    this.el.nativeElement.setAttribute('disabled', 'true');
  }

  private enable(): void {
    this.el.nativeElement.removeAttribute('disabled');
  }
}
