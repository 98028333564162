import { Injectable } from '@angular/core';
import { PageContentTitle } from '@interfaces/page-content-title.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageContentTitleService {
  private subjectContentTitle$ = new BehaviorSubject<PageContentTitle>({
    title: '',
    description: ""//'Ingresa los datos solicitados y encontraremos el mejor precio para asegurar tu auto.',
  });

  constructor() {}

  setContentTitle(title: string, description: string): void {
    this.subjectContentTitle$.next({ title, description });
  }

  get getContentTitle$(): Observable<PageContentTitle> {
    return this.subjectContentTitle$.asObservable();
  }
}
