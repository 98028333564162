import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { SeguroStore } from '@interfaces/seguro-store.interface';
import { Store } from '@services/store.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private store: Store<SeguroStore>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let state = this.store.getState();
    let parametro = environment.IdApp.toString();
    if (state && state.token) {
      const httpHeaders = new HttpHeaders({
        Authorization: 'bearer ' + state.token,
      });

      req = req.clone({
        headers: httpHeaders,
      });
    } else {
      const httpHeaders = new HttpHeaders({
        APP: parametro,
      });

      req = req.clone({
        headers: httpHeaders,
      });
    }

    return next.handle(req);
  }
}
