<div class="bs-separador h-100"></div>

<div class="z-page-loader" *ngIf="loader_html">
  <span class="bs-loader-html w-96"></span>
  <p>Cargando</p>
</div>

<div class="z-contenedor">
  <h1 class="z-titulo">Textos</h1>
  <div class="bs-separador h-10 th-1 lineal"></div>
  <div class="row">
    <div class="col">
      <h1 class="z-titulo">
        Titular h1 - min 2.2rem max 4rem (1rem 10px) - Lorem ipsum do
      </h1>
      <div class="bs-separador h-35"></div>
      <h2 class="z-titulo z-negro">
        Titular negro h2 - min 1.8rem max 2-2rem (1rem 10px) - Lorem ipsum dolor
        sit, amet consectetur adipisicing elit.
      </h2>
      <div class="bs-separador h-15"></div>
      <h2 class="z-titulo">
        Titular azul h2 - min 1.8rem max 2.2rem (1rem 10px) - Lorem ipsum dolor
        sit, amet consectetur adipisicing elit.
      </h2>
      <div class="bs-separador h-30"></div>
      <h3 class="z-titulo">
        titular h3 - min 1.8rem max 2rem (1rem 10px) - Lorem ipsum dolor sit,
        amet consectetur adipisicing elit.
      </h3>
      <div class="bs-separador h-25"></div>
      <h4 class="z-titulo">
        titular h4 1.8rem (1rem 10px) Lorem ipsum dolor sit, amet consectetur
        adipisicing elit.
      </h4>
      <div class="bs-separador h-20"></div>
      <h5 class="z-titulo">
        titular h5 1.5rem (1rem 10px)Lorem icdmpsum dolor sit, amet consectetur
        adipisicing elit.
      </h5>
      <div class="bs-separador h-15"></div>
      <p>
        Párrafo p 1.5rem Lorem ipsum dolor sit amet, consectetur adipisicing
        elit. Repellat, cupiditate. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Minima quia rerum quos quis quasi dolorum officiis?
        Saepe incidunt officiis harum <strong>commodi corrupti</strong>,
        obcaecati repellat vitae tempora, ad voluptatum nisi ullam repellendus
        voluptatem optio placeat mollitia aliquam ab omnis. Consectetur dolorum
        dolores repudiandae sapiente libero? Sint saepe eos dicta eligendi. Eius
        omnis explicabo facilis cum magn
        <a href="#" title="tooltip hover">Ducimus dolores</a>, amet hic est
        consequuntur, accusantium vero rem porro voluptate, ab earum! Officiis
        non tempore atque quae accusantium dignissimos provident magni corporis
        repellat, eligendi cum consectetur odit incidunt saepe nobis sint fugit
        eius aut alias? Officiis error at eaque quisquam laborum dolorum
        laboriosam magni!
      </p>
      <div class="bs-separador h-15"></div>
      <ul class="z-lista">
        <li>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
          aspernatur voluptas quas maiores eum distinctio nostrum velit iure,
          laboriosam, libero, ipsam deserunt! Repellendus, recusandae ullam
          expedita pariatur distinctio sequi, accusantium mollitia fugiat sit
          corrupti sed porro aliquid. Error natus debitis assumenda sint
          eveniet, expedita rerum aliquid numquam dolorem atque doloribus cum
          incidunt deserunt voluptas repellat a officia! Ducimus deleniti,
          corporis, dolorum possimus eveniet aliquam odit minus, blanditiis
          laudantium voluptas molestias iste? Sint distinctio eos enim
          voluptatem rem rerum temporibus pariatur ratione dolorum sequi!
          Magnam, rerum soluta sunt vero tempore, tempora temporibus eum facilis
          ipsum autem qui cum dolorum nesciunt itaque ipsa obcaecati
          consequuntur asperiores distinctio. Iusto mollitia, consectetur veniam
          maxime debitis dolorem tempora repellendus sunt molestiae id incidunt
          facilis, minima labore vel asperiores ducimus voluptas cum inventore
          minus aut non.
        </li>
        <li>
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Reprehenderit, voluptatibus?
        </li>
        <li>
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Reprehenderit, voluptatibus?
        </li>
        <li>
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Reprehenderit, voluptatibus?
        </li>
      </ul>
      <div class="bs-separador h-15"></div>
      <ol class="z-lista">
        <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
      </ol>
      <div class="bs-separador h-15"></div>
      <div class="z-lista">
        <span> 3 </span>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur ut,
          eos voluptas vel eum molestiae quaerat voluptatibus quod harum!
          Obcaecati ut reprehenderit error voluptatum sint a nisi quaerat animi
          voluptate?
        </p>
      </div>
      <div class="z-lista">
        <span class="check"> </span>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur ut,
          eos voluptas vel eum molestiae quaerat voluptatibus quod harum!
          Obcaecati ut reprehenderit error voluptatum sint a nisi quaerat animi
          voluptate?
        </p>
      </div>
      <div class="bs-separador h-15"></div>
      <label>
        <h5 class="z-titulo z-up">Etiqueta formulario</h5>
      </label>
      <p class="bs-caption">Caption text</p>
      <div class="bs-separador h-10"></div>
      <p class="bs-caption bs-error">Caption error text</p>
      <div class="bs-separador h-20"></div>
      <small>
        Lorem ipsum dolor
        <strong>
          sit amet consectetur adipisicing elit. Quae aspernatur voluptas quas
          maiores</strong
        >
        eum <a href="#">distinctio</a> nostrum velit iure, laboriosam, libero,
        ipsam deserunt! Repellendus, recusandae ullam expedita pariatur
        distinctio sequi, accusantium mollitia fugiat sit corrupti sed porro
        aliquid. Error natus debitis assumenda sint eveniet, expedita rerum
        aliquid numquam dolorem atque doloribus cum incidunt deserunt voluptas
        repellat a officia! Ducimus deleniti, corporis, dolorum possimus eveniet
        aliquam odit minus, blanditiis laudantium voluptas molestias iste?
      </small>
    </div>
  </div>
  <div class="bs-separador h-30 lineal th-1"></div>
  <div class="row">
    <div class="col-12">
      <div class="bs-separador h-30"></div>
    </div>
    <div class="col">
      <h1 class="z-titulo z-contenido">
        Titular CONTENIDO XL Lorem ipsum dolor
      </h1>
      <h2 class="z-titulo z-contenido">
        Titular CONTENIDO L Ipdum door amet etc
      </h2>
      <h3 class="z-titulo z-contenido">
        Titular CONTENIDO M conceptim etcm concetus
      </h3>
    </div>
  </div>
</div>

<div class="bs-separador h-30 th-1 lineal"></div>
<div class="z-contenedor">
  <h1 class="z-titulo">Botones</h1>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-primary">Primario</a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a class="bs-btn bs-btn-primary" disabled>Primario disabled</a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-primary full">Primario full</a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-primary-negro">Primario negro</a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-primary-negro" disabled
        >Primario negro disabled</a
      >
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-primary-negro full"
        >Primario negro full</a
      >
    </div>
  </div>
  <div class="bs-separador h-30"></div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-secondary">Secundario</a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-secondary" disabled
        >Secundario disabled</a
      >
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-secondary full">Secundario full</a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-secondary-negro">Secundario negro</a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-secondary-negro" disabled
        >Secundario negro disabled</a
      >
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-secondary-negro full"
        >Secundario negro full</a
      >
    </div>
  </div>
  <div class="bs-separador h-30"></div>
  <div class="row">
    <div class="col-12 col-md-4 col-lg-3">
      <a
        id="bs-btn-contactenos-header"
        href="#"
        target="_blank"
        class="bs-btn bs-btn-download"
      >
        <figure>
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.6355 26H4.36451C1.99521 26 0 24.0671 0 21.6355V4.36451L4.36451 0H21.6355C24.0048 0 26 1.93285 26 4.36451V21.6355L21.6355 26ZM3.73486 3.11129C3.42311 3.11129 3.11136 3.42307 3.11136 3.79717V22.2652C3.11136 22.577 3.36076 22.8887 3.73486 22.8887H22.2651C22.5769 22.8887 22.8886 22.6393 22.8886 22.2652V3.73479C22.8886 3.42304 22.6392 3.11129 22.2651 3.11129H3.73486Z"
            />
            <path
              d="M17.2708 18.1439H15.2133L12.9063 14.1535L10.5994 18.1439H8.47949L11.8464 12.8441L8.47949 7.60672H10.5994L12.9063 11.5971L15.2133 7.60672H17.2708L13.9039 12.8441L17.2708 18.1439Z"
            />
          </svg>
        </figure>
        <span> Descarga de XLS </span>
      </a>
    </div>
    <div class="col-12 col-md-4 col-lg-3">
      <a
        id="bs-btn-contactenos-header"
        href="#"
        target="_blank"
        class="bs-btn bs-btn-download"
      >
        <figure>
          <svg
            width="24"
            height="26"
            viewBox="0 0 24 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5243 16.3054C19.0125 15.3549 16.5994 14.6968 14.4788 14.5505C14.2594 14.3312 14.1131 14.1119 13.8938 13.8925C12.0657 11.8452 11.3344 8.84736 11.115 7.23877C11.115 6.87318 11.0419 6.65385 11.0419 6.43449C11.0419 6.28826 10.9688 5.92266 10.6032 5.92266C10.4569 5.92266 10.3838 5.99578 10.3107 6.0689C10.1644 6.21514 10.2375 6.36138 10.2375 6.58074C10.2375 6.80009 10.3106 7.09254 10.3838 7.38501C10.53 8.99361 10.6032 12.0645 9.36004 14.5505C9.21379 14.7699 9.06754 15.0624 8.99442 15.2817C6.65443 15.9398 4.75317 17.1828 4.53379 18.1333C4.46067 18.4989 4.53383 18.8645 4.7532 19.0839C5.0457 19.3763 5.4113 19.5226 5.77692 19.5226C6.87379 19.5226 8.11693 18.2796 9.5063 15.8667C10.0913 15.7205 10.7494 15.5742 11.3344 15.5011C11.5538 15.5011 11.9194 15.428 12.1388 15.428C12.7969 15.3549 13.455 15.2817 14.1862 15.2817C15.8681 17.0366 17.2575 17.914 18.2812 17.914C18.8662 17.914 19.305 17.6215 19.5975 17.1828C19.6706 16.9635 19.7437 16.5979 19.5243 16.3054ZM5.70381 18.7914C5.55756 18.7914 5.41129 18.7183 5.26504 18.572C5.19192 18.4989 5.19193 18.4258 5.19193 18.3527C5.33818 17.8409 6.50818 16.9635 8.26317 16.3054C7.31255 17.8409 6.36193 18.7914 5.70381 18.7914ZM11.9194 14.6968C11.7 14.6968 11.3344 14.7699 11.115 14.7699C10.7494 14.8431 10.3107 14.843 9.87192 14.9893C9.87192 14.9893 9.8719 14.9161 9.94503 14.9161C10.53 13.7463 10.8956 12.2839 11.0419 10.6022C11.6269 12.1377 12.3581 13.4538 13.2356 14.4774L13.3087 14.5505C12.87 14.5505 12.4313 14.6237 11.9194 14.6968ZM18.7931 16.8903C18.6468 17.1097 18.5006 17.2559 18.2081 17.2559C17.55 17.2559 16.5994 16.671 15.3563 15.5011C17.1844 15.7205 18.5737 16.3054 18.7931 16.7441C18.8662 16.7441 18.8662 16.8172 18.7931 16.8903Z"
            />
            <path
              d="M16.0143 0H3.36373C1.5356 0 0 1.53545 0 3.3634V22.4471C0 24.2751 1.5356 25.8105 3.36373 25.8105H20.5481C22.3762 25.8105 23.9118 24.2751 23.9118 22.4471V7.9698L16.0143 0ZM20.6212 23.6901H3.43683C2.70558 23.6901 2.12059 23.1052 2.12059 22.374V3.3634C2.12059 2.63222 2.70558 2.04729 3.43683 2.04729H15.2831V6.43435C15.2831 7.75047 16.3068 8.77412 17.6231 8.77412H21.9375L22.0106 22.4471C21.9374 23.1052 21.3524 23.6901 20.6212 23.6901Z"
            />
          </svg>
        </figure>
        <span> Descarga de PDF </span>
      </a>
    </div>
    <div class="col-12 col-md-4 col-lg-3">
      <a
        id="bs-btn-contactenos-header"
        href="#"
        target="_blank"
        class="bs-btn bs-btn-download"
      >
        <figure>
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.6355 26H4.36451C1.99521 26 0 24.0671 0 21.6355V4.36451L4.36451 0H21.6355C24.0048 0 26 1.93285 26 4.36451V21.6355L21.6355 26ZM3.73486 3.11129C3.42311 3.11129 3.11136 3.42307 3.11136 3.79717V22.2652C3.11136 22.577 3.36076 22.8887 3.73486 22.8887H22.2651C22.5769 22.8887 22.8886 22.6393 22.8886 22.2652V3.7348C22.8886 3.42304 22.6392 3.11129 22.2651 3.11129H3.73486Z"
            />
            <path
              d="M16.0652 17.9482H14.5064L12.5112 10.3415L10.4537 17.9482H8.95728L6.52561 7.78518H8.20907L9.76783 15.3919L11.763 7.78518H13.1971L15.2546 15.3919L16.7511 7.78518H18.4969L16.0652 17.9482Z"
            />
          </svg>
        </figure>
        <span> Descarga de WORD </span>
      </a>
    </div>
    <div class="col-12 col-md-4 col-lg-4">
      <a
        id="bs-btn-contactenos-header"
        href="#"
        target="_blank"
        class="bs-btn bs-btn-download"
        disabled
      >
        <figure>
          <svg
            width="24"
            height="26"
            viewBox="0 0 24 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5243 16.3054C19.0125 15.3549 16.5994 14.6968 14.4788 14.5505C14.2594 14.3312 14.1131 14.1119 13.8938 13.8925C12.0657 11.8452 11.3344 8.84736 11.115 7.23877C11.115 6.87318 11.0419 6.65385 11.0419 6.43449C11.0419 6.28826 10.9688 5.92266 10.6032 5.92266C10.4569 5.92266 10.3838 5.99578 10.3107 6.0689C10.1644 6.21514 10.2375 6.36138 10.2375 6.58074C10.2375 6.80009 10.3106 7.09254 10.3838 7.38501C10.53 8.99361 10.6032 12.0645 9.36004 14.5505C9.21379 14.7699 9.06754 15.0624 8.99442 15.2817C6.65443 15.9398 4.75317 17.1828 4.53379 18.1333C4.46067 18.4989 4.53383 18.8645 4.7532 19.0839C5.0457 19.3763 5.4113 19.5226 5.77692 19.5226C6.87379 19.5226 8.11693 18.2796 9.5063 15.8667C10.0913 15.7205 10.7494 15.5742 11.3344 15.5011C11.5538 15.5011 11.9194 15.428 12.1388 15.428C12.7969 15.3549 13.455 15.2817 14.1862 15.2817C15.8681 17.0366 17.2575 17.914 18.2812 17.914C18.8662 17.914 19.305 17.6215 19.5975 17.1828C19.6706 16.9635 19.7437 16.5979 19.5243 16.3054ZM5.70381 18.7914C5.55756 18.7914 5.41129 18.7183 5.26504 18.572C5.19192 18.4989 5.19193 18.4258 5.19193 18.3527C5.33818 17.8409 6.50818 16.9635 8.26317 16.3054C7.31255 17.8409 6.36193 18.7914 5.70381 18.7914ZM11.9194 14.6968C11.7 14.6968 11.3344 14.7699 11.115 14.7699C10.7494 14.8431 10.3107 14.843 9.87192 14.9893C9.87192 14.9893 9.8719 14.9161 9.94503 14.9161C10.53 13.7463 10.8956 12.2839 11.0419 10.6022C11.6269 12.1377 12.3581 13.4538 13.2356 14.4774L13.3087 14.5505C12.87 14.5505 12.4313 14.6237 11.9194 14.6968ZM18.7931 16.8903C18.6468 17.1097 18.5006 17.2559 18.2081 17.2559C17.55 17.2559 16.5994 16.671 15.3563 15.5011C17.1844 15.7205 18.5737 16.3054 18.7931 16.7441C18.8662 16.7441 18.8662 16.8172 18.7931 16.8903Z"
            />
            <path
              d="M16.0143 0H3.36373C1.5356 0 0 1.53545 0 3.3634V22.4471C0 24.2751 1.5356 25.8105 3.36373 25.8105H20.5481C22.3762 25.8105 23.9118 24.2751 23.9118 22.4471V7.9698L16.0143 0ZM20.6212 23.6901H3.43683C2.70558 23.6901 2.12059 23.1052 2.12059 22.374V3.3634C2.12059 2.63222 2.70558 2.04729 3.43683 2.04729H15.2831V6.43435C15.2831 7.75047 16.3068 8.77412 17.6231 8.77412H21.9375L22.0106 22.4471C21.9374 23.1052 21.3524 23.6901 20.6212 23.6901Z"
            />
          </svg>
        </figure>
        <span> Descarga disabled </span>
      </a>
    </div>
  </div>
  <div class="bs-separador h-30"></div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a
        id="bs-btn-contactenos-header"
        href="#"
        target="_blank"
        class="bs-btn bs-btn-secondary icon"
      >
        <figure>
          <img
            src="/assets/img/svg/icon-telefono-naranja.svg"
            alt="Ícono teléfono naranja"
          />
        </figure>
        <span>
          Texto e ícono
          <br />
          Dos líneas
        </span>
      </a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a
        id="bs-btn-contactenos-header"
        href="#"
        target="_blank"
        class="bs-btn bs-btn-secondary icon"
        disabled
      >
        <figure>
          <img
            src="/assets/img/svg/icon-telefono-naranja.svg"
            alt="Ícono teléfono naranja"
          />
        </figure>
        <span>
          Texto e ícono
          <br />
          Dos líneas
        </span>
      </a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a
        id="bs-btn-contactenos-header"
        href="#"
        target="_blank"
        class="bs-btn bs-btn-primary icon"
      >
        <figure>
          <img
            src="/assets/img/svg/icon-telefono-blanco.svg"
            alt="Ícono teléfono Blanco"
          />
        </figure>
        <span>
          Texto e ícono
          <br />
          Dos líneas
        </span>
      </a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a
        id="bs-btn-contactenos-header"
        href="#"
        target="_blank"
        class="bs-btn bs-btn-primary icon"
        disabled
      >
        <figure>
          <img
            src="/assets/img/svg/icon-telefono-blanco.svg"
            alt="Ícono teléfono Blanco"
          />
        </figure>
        <span>
          Texto e ícono
          <br />
          disabled
        </span>
      </a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-link">Enlace Simple</a>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <a href="#" class="bs-btn bs-btn-link" disabled>Enlace Simple disabled</a>
    </div>
  </div>
</div>
<div class="bs-separador h-30 th-1 lineal"></div>
<div class="z-contenedor">
  <h1 class="z-titulo">Formularios</h1>

  <h5 class="z-titulo">Checkbox</h5>
  <div class="bs-separador"></div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="bs-forms">
        <h5 class="z-label simple">
          Checkbox campos alineados etiqueta simple
        </h5>
        <section class="mix">
          <p-checkbox
            [disabled]="true"
            inputId="Grupo_1"
            name="Grupo_1"
            value="dato_numero_1"
            [(ngModel)]="tipo_checkbox"
            inputId="gru1"
            label="Dato numero 1"
          ></p-checkbox>
          <p-checkbox
            inputId="Grupo_1"
            name="Grupo_2"
            value="dato_numero_2"
            [(ngModel)]="tipo_checkbox"
            inputId="gru2"
            label="Dato numero 2"
          ></p-checkbox>
        </section>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="bs-forms">
        <h5 class="z-label">Checkbox apilados etiqueta bold</h5>

        <p-checkbox
          inputId="Grupo_1"
          name="Grupo_1"
          value="dato_numero_1"
          [(ngModel)]="tipo_checkbox"
          inputId="gru1"
          label="Dato numero 1"
        ></p-checkbox>
        <p-checkbox
          inputId="Grupo_1"
          name="Grupo_2"
          value="dato_numero_2"
          [(ngModel)]="tipo_checkbox"
          inputId="gru2"
          label="Dato numero 2"
        ></p-checkbox>
      </div>
    </div>
  </div>
  <div class="bs-separador h-30 th-1 lineal"></div>
  <div class="row">
    <h5 class="z-titulo">Input Fields</h5>
    <div class="bs-separador"></div>
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-1" class="bs-forms">
        <p class="z-label">Etiqueta campo simple</p>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <input
          type="text"
          placeholder="Placeholder text"
          pInputText
          id="form-1"
        />
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-2" class="bs-forms">
        <p class="z-label">
          Etiqueta campo simple <span class="z-required">*</span>
        </p>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <input
          type="text"
          placeholder="Placeholder text"
          pInputText
          value="Lorem upsum"
          class="ng-invalid ng-dirty"
          id="form-2"
        />
        <p class="bs-caption bs-error">Caption text error</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-3" class="bs-forms">
        <p class="z-label">
          Etiqueta <span class="z-optional">(Opcional)</span>
        </p>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <input
          type="text"
          placeholder="Placeholder text"
          pInputText
          value="Texto ingresado"
          id="form-3"
        />
        <p class="bs-caption">Caption text</p>
      </label>
    </div>

    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-4" class="bs-forms">
        <p class="z-label" disabled>Etiqueta campo disabled</p>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <input
          type="text"
          placeholder="Placeholder text"
          pInputText
          value="Texto ingresado"
          disabled
          id="form-4"
        />
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <form>
        <label for="form-contrasena-id" class="bs-forms">
          <p class="z-label">Contraseña</p>
          <section class="mix icon">
            <!-- INPUT ASOCIADO A PRIME NG -->
            <input
              [attr.type]="mostrar_contrasena ? 'text' : 'password'"
              placeholder="*********"
              pInputText
              id="form-contrasena-id"
              autocomplete="off"
            />
            <a
              class="bs-btn bs-btn-form icons float no-bg password"
              [ngClass]="{ active: mostrar_contrasena }"
              (click)="metodoMostrarContrasena()"
            >
              <figure class="icon"></figure>
            </a>
          </section>
          <p class="bs-caption">Caption text</p>
        </label>
      </form>
    </div>
    <div class="col-12">
      <div class="bs-separador h-30 th-1 lineal"></div>
    </div>
    <div class="col-12">
      <h5 class="z-titulo">Formatos especiales</h5>
    </div>
    <div class="col-12">
      <div class="bs-separador h-20"></div>
    </div>
    <div class="col-12 col-sm-6 col-lg-4">
      <label for="form-11" class="bs-forms">
        <p class="z-label">Nombre campo</p>
        <section class="mix">
          <!-- INPUT ASOCIADO A PRIME NG -->
          <input
            type="text"
            placeholder="Placeholder text"
            pInputText
            id="form-11"
          />
          <a href="#" class="bs-btn bs-btn-form icons">
            <figure class="icon">
              <img
                src="assets/img/svg/icon-arrow-next-w.svg"
                alt="Icono del boton lorem ipdim"
              />
            </figure>
          </a>
        </section>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-sm-6 col-lg-4">
      <label for="form-12" class="bs-forms">
        <p class="z-label">Dos Líneas</p>
        <section class="mix">
          <!-- INPUT ASOCIADO A PRIME NG -->
          <input
            type="text"
            placeholder="Placeholder text"
            pInputText
            id="form-12"
          />
          <a href="#" class="bs-btn bs-btn-form"> Etiqueta Dible </a>
        </section>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-sm-6 col-lg-4">
      <label for="form-12" class="bs-forms">
        <p class="z-label">Nombre campo</p>
        <section class="mix">
          <!-- INPUT ASOCIADO A PRIME NG -->
          <input
            type="text"
            placeholder="Placeholder text"
            pInputText
            id="form-12"
          />
          <a href="#" class="bs-btn bs-btn-form auto"> Etiqueta doble </a>
        </section>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-sm-6 col-lg-4">
      <label for="form-22" class="bs-forms">
        <p class="z-label error">Nombre campo error</p>
        <section class="mix">
          <!-- INPUT ASOCIADO A PRIME NG -->
          <input
            type="text"
            placeholder="Placeholder text"
            pInputText
            id="form-22"
            class="ng-invalid ng-dirty"
          />
          <a href="#" class="bs-btn bs-btn-form auto">
            Etiqueta triple triple</a
          >
        </section>
        <p class="bs-caption bs-error">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-sm-6 col-lg-4">
      <label for="form-13" class="bs-forms">
        <p class="z-label" disabled>Nombre campo disabled</p>
        <section class="mix">
          <!-- INPUT ASOCIADO A PRIME NG -->
          <input
            type="text"
            placeholder="Placeholder text"
            pInputText
            id="form-13"
            disabled
          />
          <a class="bs-btn bs-btn-form" disabled> Etiqueta </a>
        </section>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
  </div>
  <div class="bs-separador h-30 th-1 lineal"></div>
  <h5 class="z-titulo">Radio Button</h5>
  <div class="bs-separador"></div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="bs-forms">
        <p class="z-label">Etiqueta grupo</p>
        <p-radioButton
          name="Op1"
          value="Op1"
          [(ngModel)]="opciones_radiobutton"
          inputId="radioButton_1"
          label="Opcion 1"
        ></p-radioButton>
        <p-radioButton
          name="Op1"
          value="Op2"
          [(ngModel)]="opciones_radiobutton"
          inputId="radioButton_2"
          label="Opcion 2"
        ></p-radioButton>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="bs-forms">
        <p-radioButton
          [disabled]="true"
          name="Op1"
          value="Op1"
          [(ngModel)]="opciones_radiobutton"
          inputId="radioButton_5"
          label="Opcion 5 disabled"
        ></p-radioButton>
        <p-radioButton
          class="ng-dirty ng-invalid"
          name="Op1"
          value="Op4"
          [(ngModel)]="opciones_radiobutton"
          inputId="radioButton_4"
          label="Opcion 4"
        ></p-radioButton>
      </div>
    </div>
  </div>
  <div class="bs-separador h-30 th-1 lineal"></div>
  <h5 class="z-titulo">Dropdown</h5>
  <div class="bs-separador"></div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-15" class="bs-forms">
        <h5>Dropdown</h5>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <p-dropdown
          id="tipocontacto"
          [options]="datos"
          [(ngModel)]="tipo_datos"
          name="tipocontacto"
          placeholder="Selecciona forma"
          optionLabel="nombre"
          optionValie="id"
          [showClear]="false"
          emptyMessage="Sin Datos"
        ></p-dropdown>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-15" class="bs-forms">
        <h5>Dropdown error</h5>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <p-dropdown
          id="tipocontacto"
          [options]="datos"
          [(ngModel)]="tipo_datos"
          name="tipocontacto"
          placeholder="Selecciona forma"
          optionLabel="nombre"
          optionValie="id"
          [showClear]="false"
          emptyMessage="Sin Datos"
          class="ng-invalid ng-dirty"
        ></p-dropdown>
        <p class="bs-caption bs-error">Caption error</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-15" class="bs-forms">
        <h5 disabled>Dropdown</h5>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <p-dropdown
          [disabled]="true"
          id="tipocontacto"
          [options]="datos"
          [(ngModel)]="tipo_datos"
          name="tipocontacto"
          placeholder="Selecciona forma"
          optionLabel="nombre"
          optionValie="id"
          [showClear]="false"
          emptyMessage="Sin Datos"
        ></p-dropdown>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-15" class="bs-forms">
        <h5>Dropdown filter</h5>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <p-dropdown
          [filter]="true"
          id="tipocontacto"
          [options]="datos"
          [(ngModel)]="tipo_datos"
          name="tipocontacto"
          placeholder="Selecciona forma"
          optionLabel="nombre"
          optionValie="id"
          [showClear]="false"
          emptyMessage="Sin Datos"
          [autoDisplayFirst]="false"
          filterPlaceholder="Buscar un item "
          emptyFilterMessage="Sin datos"
        ></p-dropdown>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label class="bs-forms">
        <h5>Multiselect filter</h5>
        <p-multiSelect
          [options]="marcas"
          [(ngModel)]="marcas_selected"
          optionLabel="nombre"
          placeholder="Buscar por marca"
          filterPlaceHolder="Buscar"
          emptyFilterMessage="Sin resultados"
        ></p-multiSelect>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label class="bs-forms">
        <h5>Multiselect error</h5>
        <p-multiSelect
          [options]="marcas"
          [(ngModel)]="marcas_selected"
          optionLabel="nombre"
          placeholder="Buscar por marca"
          filterPlaceHolder="Buscar"
          emptyFilterMessage="Sin resultados"
          class="ng-invalid ng-dirty"
        ></p-multiSelect>
        <p class="bs-caption bs-error">Caption error</p>
      </label>
    </div>
  </div>
  <div class="bs-separador h-30 th-1 lineal"></div>
  <h5 class="z-titulo">Fecha y Hora</h5>
  <div class="bs-separador"></div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-1411" class="bs-forms">
        <h5>Etiqueta fecha</h5>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <p-calendar
          [(ngModel)]="fecha"
          [firstDayOfWeek]="1"
          inputId="form-1411"
          [showIcon]="true"
          dateFormat="dd/mm/yy"
        ></p-calendar>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-141112" class="bs-forms">
        <h5>Etiqueta fecha con selector</h5>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <p-calendar
          [showOtherMonths]="true"
          [selectOtherMonths]="true"
          [monthNavigator]="true"
          [yearNavigator]="true"
          yearRange="2000:2030"
          [(ngModel)]="fecha2"
          [firstDayOfWeek]="1"
          inputId="form-141112"
          [showIcon]="true"
          dateFormat="dd/mm/yy"
        ></p-calendar>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-15" class="bs-forms">
        <h5 disabled>Etiqueta fecha disabled</h5>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <p-calendar
          disabled
          [(ngModel)]="fecha"
          [firstDayOfWeek]="1"
          inputId="form-15"
          [showIcon]="true"
          dateFormat="dd/mm/yy"
        ></p-calendar>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-14" class="bs-forms">
        <h5>Etiqueta fecha error</h5>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <p-calendar
          [(ngModel)]="fecha"
          [firstDayOfWeek]="1"
          inputId="form-14"
          [showIcon]="true"
          class="ng-invalid ng-dirty"
          dateFormat="dd/mm/yy"
        ></p-calendar>
        <p class="bs-caption bs-error">Caption error</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-fecha" class="bs-forms">
        <h5>Etiqueta Hora</h5>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <p-calendar
          [(ngModel)]="hora"
          [showTime]="true"
          [showSeconds]="true"
          inputId="form-fecha"
          [timeOnly]="true"
        ></p-calendar>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <label for="form-fecha" class="bs-forms">
        <h5>Etiqueta Fecha y Hora</h5>
        <!-- INPUT ASOCIADO A PRIME NG -->
        <p-calendar
          [(ngModel)]="hora"
          [showTime]="true"
          [showSeconds]="true"
          inputId="form-fecha"
          hourFormat="12"
          [showSeconds]="false"
        ></p-calendar>
        <p class="bs-caption">Caption text</p>
      </label>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <p-calendar [(ngModel)]="fecha" [inline]="true"></p-calendar>
      <div class="bs-separador h30"></div>
    </div>
  </div>
</div>
<div class="bs-separador h-30 th-1 lineal"></div>
<div class="z-contenedor">
  <h1 class="z-titulo">Selectores</h1>
  <div class="row">
    <div class="col-12">
      <div class="bs-separador h-30"></div>
    </div>
    <div class="col-12 col-md-6">
      <a href="#" class="z-selector w-100p">
        <span class="label">
          Etiqueta
          <span class="bs-caption"> Caption </span>
        </span>
        <span class="arrow"> </span>
      </a>
    </div>
    <div class="col-12 col-md-6">
      <a href="#" class="z-selector w-100p">
        <span class="label"> Etiqueta </span>
        <span class="arrow"> </span>
      </a>
    </div>
    <div class="col-12 col-md-6">
      <a href="#" class="z-selector w-100p" disabled>
        <span class="label"> Etiqueta disabled </span>
        <span class="arrow"> </span>
      </a>
    </div>
    <div class="col-12 col-md-6">
      <a href="#" class="z-selector w-100p visited">
        <span class="label"> Etiqueta visited </span>
        <span class="arrow"> </span>
      </a>
    </div>
  </div>
  <div class="bs-separador h-20"></div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
      <a href="#" class="z-selector w-100p">
        <span class="label"> Etiqueta </span>
        <span class="bs-caption"> Caption </span>
      </a>
    </div>
    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
      <a href="#" class="z-selector w-100p" disabled>
        <span class="label"> Etiqueta disabled </span>
        <span class="bs-caption"> Caption </span>
      </a>
    </div>
    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
      <a href="#" class="z-selector">
        <span class="label"> Etiqueta size auto </span>
        <span class="bs-caption"> Caption </span>
      </a>
    </div>
    <div class="col-12">
      <div class="bs-separador h-20"></div>
    </div>
    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
      <a href="#" class="z-selector w-100p">
        <figure>
          <img src="assets/img/svg/icon-selector.svg" alt="Icon Selector" />
        </figure>
        <span class="label">
          Etiqueta
          <span class="bs-caption"> Caption </span>
        </span>
        <span class="arrow"> </span>
      </a>
    </div>
    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
      <a href="#" class="z-selector w-100p">
        <figure>
          <img src="assets/img/svg/icon-selector.svg" alt="Icon Selector" />
        </figure>
        <span class="label"> Etiqueta </span>
        <span class="arrow"> </span>
      </a>
    </div>
    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
      <a href="#" class="z-selector w-100p" disabled>
        <figure>
          <img src="assets/img/svg/icon-selector.svg" alt="Icon Selector" />
        </figure>
        <span class="label"> Etiqueta disabled </span>
        <span class="arrow"> </span>
      </a>
    </div>
    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
      <a href="#" class="z-selector w-100p grande">
        <figure>
          <img src="assets/img/thumbnail.jpg" alt="image Selector" />
        </figure>
        <span class="label"> Etiqueta thumbnail </span>
        <span class="delete"> </span>
      </a>
    </div>
    <div class="col-12">
      <div class="bs-separador h-30"></div>
      <h5 class="z-titulo">Selector compuesto</h5>
      <div class="bs-separador h-10"></div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <a href="#" class="z-selector w-100p grande">
        <figure>
          <img
            src="assets/img/svg/ilustracion-selector.svg"
            alt="Icon Selector"
          />
        </figure>
        <span class="label">
          <span class="preface"> TITULAR </span>
          IRIS ANDREA ROJAS RETAMAL
          <span class="bs-caption"> 13.122.122-1 </span>
        </span>
        <span class="arrow"> </span>
      </a>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <a href="#" class="z-selector w-100p grande" disabled>
        <figure>
          <img
            src="assets/img/svg/ilustracion-selector.svg"
            alt="Icon Selector"
          />
        </figure>
        <span class="label">
          <span class="preface"> GRANDE DISABLED </span>
          IRIS ANDREA ROJAS RETAMAL
          <span class="bs-caption"> 13.122.122-1 </span>
        </span>
        <span class="arrow"> </span>
      </a>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <a href="#" class="z-selector w-100p">
        <figure>
          <img
            src="assets/img/svg/ilustracion-selector-60.svg"
            alt="Icon Selector"
          />
        </figure>
        <span class="label"> Consultas médicas </span>
        <span class="arrow"> </span>
      </a>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <a href="#" class="z-selector w-100p grande">
        <figure>
          <img
            src="assets/img/svg/ilustracion-selector-30.svg"
            alt="Icon Selector"
          />
        </figure>
        <span class="label">
          <span class="z-title"> Consultas médicas </span>
          <span class="z-description">
            Kinesiologia / Fonoaudiologia / Nutrición / Terapia
            ocupacional/psicopedagogia / psicología
          </span>
        </span>
        <span class="arrow"> </span>
      </a>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <a href="#" class="z-selector w-100p grande suspended">
        <figure>
          <img
            src="assets/img/svg/ilustracion-selector.svg"
            alt="Icon Selector"
          />
        </figure>
        <span class="label">
          <span class="preface"> GRANDE </span>
          IRIS ANDREA ROJAS RETAMAL
          <span class="bs-caption"> 13.122.122-1 </span>
          <span class="z-chip mini gris-50"> Suspendida </span>
        </span>
        <span class="arrow"> </span>
      </a>
    </div>

    <div class="col-12">
      <div class="bs-separador h-30"></div>
      <h5 class="z-titulo">Selector Simple</h5>
      <div class="bs-separador h-10"></div>
    </div>
    <div class="col-12 col-sm-6">
      <button class="z-selector simple">
        <span class="label"> Etiqueta </span>
      </button>
      <button href="#" class="z-selector simple" disabled>
        <span class="label"> Etiqueta disabled </span>
      </button>
      <button href="#" class="z-selector simple active">
        <span class="label"> Etiqueta active </span>
      </button>
    </div>
    <div class="col-12 col-sm-6">
      <button class="z-selector simple">
        <span class="label"> Etiqueta Lorem ipsum dolor sit amet </span>
      </button>
    </div>
    <div class="col-12">
      <div class="bs-separador h-30"></div>
      <h5 class="z-titulo">Selector Sombra (sin borde)</h5>
      <div class="bs-separador h-10"></div>
    </div>
    <div class="col-12 col-sm-6">
      <a href="#" class="z-selector w-100p grande sombra">
        <figure>
          <img src="assets/img/svg/logo-tarjeta.svg" alt="image Selector" />
        </figure>
        <span class="label"> Selector sombra normal </span>
        <span class="arrow"> </span>
      </a>
    </div>

    <div class="col-12 col-sm-6">
      <a href="#" class="z-selector w-100p grande sombra">
        <figure>
          <img src="assets/img/svg/logo-debito.svg" alt="image Selector" />
        </figure>
        <span class="label"> Selector sombra disabled </span>
        <span class="arrow"> </span>
      </a>
    </div>
    <div class="col-12">
      <div class="bs-separador h-30"></div>
      <h5 class="z-titulo">Selectores expandibles</h5>
      <div class="bs-separador h-10"></div>
    </div>
    <div class="col-12 col-sm-6">
      <details class="z-selector w-100p grande sombra expandible" open>
        <summary class="cta">
          <figure>
            <img src="assets/img/svg/logo-tarjeta.svg" alt="image Selector" />
          </figure>
          <span class="label"> Selector sombra expandible </span>
          <span class="arrow"> </span>
        </summary>
        <section class="data">
          <div class="z-bloque">
            <figure>
              <img src="/assets/img/logo-klap.jpg" alt="Sistema Klap" />
            </figure>
            <h5 class="z-titulo">Sistema Klap</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
              laudantium modi, doloribus, dolore sequi nihil quod maxime, vel
              quas
            </p>
            <a (click)="show_modal_3()" class="bs-btn bs-btn-link"
              >Ver Ejemplo</a
            >
          </div>
          <div class="bs-separador h-20 lineal th-1"></div>
          <div class="z-bloque">
            <figure>
              <img src="/assets/img/logo-patpass.jpg" alt="Sistema Klap" />
            </figure>
            <h5 class="z-titulo">Sistema Klap</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
              laudantium modi, doloribus, dolore sequi nihil quod maxime, vel
              quasi qui quia .
            </p>
            <a (click)="show_modal_3()" class="bs-btn bs-btn-link"
              >Ver Ejemplo</a
            >
          </div>
        </section>
      </details>
    </div>

    <div class="col-12 col-sm-6">
      <details class="z-selector w-100p grande expandible">
        <summary class="cta">
          <figure>
            <img src="assets/img/svg/logo-debito.svg" alt="image Selector" />
          </figure>
          <span class="label"> Selector sombra expandible </span>
          <span class="arrow"> </span>
        </summary>
        <section class="data">
          <div class="z-bloque">
            <figure>
              <img src="/assets/img/logo-klap.jpg" alt="Sistema Klap" />
            </figure>
            <h5 class="z-titulo">Sistema Klap</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
              laudantium modi, doloribus, dolore sequi nihil quod maxime, vel
              quas
            </p>
            <a (click)="show_modal_3()" class="bs-btn bs-btn-link"
              >Ver Ejemplo</a
            >
          </div>
          <div class="bs-separador h-20 lineal th-1"></div>
          <div class="z-bloque" disabled>
            <figure>
              <img src="/assets/img/logo-patpass.jpg" alt="Sistema Klap" />
            </figure>
            <h5 class="z-titulo">Sistema Klap</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
              laudantium modi, doloribus, dolore sequi nihil quod maxime, vel
              quasi qui quia .
            </p>
            <div class="bs-btn bs-btn-link">Ver Ejemplo</div>
          </div>
        </section>
      </details>
    </div>
  </div>
</div>
<div class="bs-separador h-30 th-1 lineal"></div>
<div class="z-contenedor">
  <h1 class="z-titulo">Modales</h1>
  <div class="row">
    <div class="col-6 col-md-3">
      <a class="bs-btn bs-btn-secondary" (click)="show_modal_1()"
        >Modal alerta</a
      >
    </div>
    <div class="col-6 col-md-3">
      <a class="bs-btn bs-btn-secondary" (click)="show_modal_2()"
        >Modal información</a
      >
    </div>

    <div class="col-6 col-md-3">
      <a class="bs-btn bs-btn-secondary" (click)="show_modal_3()"
        >Modal Tarjetas</a
      >
    </div>
  </div>
</div>

<div class="bs-separador h-30 th-1 lineal"></div>
<div class="z-contenedor">
  <h1 class="z-titulo">Bloques con borde</h1>
  <div class="row">
    <div class="col-12">
      <div class="z-bloque no-padding z-border-all gris-10 min-h-100">
        <article>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet
            illum itaque ex minima dicta dolorum temporibus eius, deleniti
            commodi, et aspernatur quaerat consectetur nulla? Aliquid, obcaecati
            voluptatibus. Pariatur voluptates asperiores harum possimus nostrum
            quisquam libero quibusdam, ratione numquam provident non unde atque
            maiores recusandae illo eum sint. Praesentium in repudiandae maxime
            tempore, doloribus a. Dolorum, nemo explicabo nam facilis dolores
            totam praesentium tempora vitae. Recusandae, eius facilis ab, nulla
            inventore ut, fuga omnis sint repellendus libero culpa reiciendis.
            Corrupti eligendi iste iusto accusamus cumque error recusandae
            dignissimos repudiandae non quisquam perspiciatis dolor expedita
            mollitia, ipsam ipsum consequatur delectus quae incidunt.
          </p>
        </article>
      </div>
    </div>
  </div>
  <div class="bs-separador h-40"></div>
</div>
<div class="bs-separador h-30 th-1 lineal"></div>
<div class="z-contenedor">
  <h1 class="z-titulo">Resumen</h1>
  <div class="z-bloque z-border-all gris-20 no-padding no-border-tablet">
    <div class="row">
      <div class="col">
        <div class="z-bloque min-h-100">
          <article>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo unde
              libero doloremque saepe autem consequuntur voluptate, modi
              excepturi fugiat distinctio minus quos deserunt quo beatae, velit
              ipsum vel eveniet totam.
            </p>
          </article>
        </div>
      </div>
      <div class="col-12 col-lg-3 z-border-left">
        <div
          class="z-bloque min-h-100 no-padding z-border gris-20 d-none d-lg-block"
        >
          <article>
            <h3 class="z-titulo z-negro">Resumen de la solicitud</h3>
          </article>
          <!-- angular component --->
          <app-desplegables-resumen></app-desplegables-resumen>
          <!-- ***************** -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bs-separador h-30 th-1 lineal"></div>
<div class="z-contenedor">
  <h1 class="z-titulo">Loaders</h1>
  <div class="row">
    <div class="col-12 col-sm-4">
      <div class="bs-separador h-20"></div>
      <span class="bs-loader-html w-48"></span>
      <div class="bs-separador h-20"></div>
      <span class="bs-loader-html w-24"></span>
      <div class="bs-separador h-20"></div>
      <span class="bs-loader-html w-96"></span>
      <div class="bs-separador h-20"></div>
      <button class="bs-btn bs-btn-secondary" (click)="show_loaderhtml()">
        Ver Loader
      </button>
    </div>
  </div>
</div>

<div class="bs-separador h-30 th-1 lineal"></div>
<div class="z-contenedor">
  <h1 class="z-titulo">Barra progreso</h1>
  <div class="row">
    <div class="col-12">
      <p-progressBar [value]="90" [showValue]="false"></p-progressBar>
    </div>
  </div>
</div>
<div class="bs-separador h-30 th-1 lineal"></div>
<div class="z-contenedor">
  <h1 class="z-titulo">Pasos</h1>
  <div class="row">
    <div class="col-12 col-xs-6 col-md-4">
      <div class="z-pasos-indice active">
        <span class="index">1</span>
        <h2 class="z-titulo z-contenido">Proceso Activo</h2>
        <span class="pasos"> Paso 1 de 4 </span>
      </div>
    </div>
    <div class="col-12 col-xs-6 col-md-4">
      <div class="z-pasos-indice">
        <span class="index">2</span>
        <h2 class="z-titulo z-contenido">Proceso en espera</h2>
      </div>
    </div>
    <div class="col-12 col-xs-6 col-md-4">
      <div class="z-pasos-indice completed">
        <span class="index">3</span>
        <h2 class="z-titulo z-contenido">Completo</h2>
      </div>
    </div>
    <div class="col-12">
      <div class="bs-separador h-30"></div>
    </div>
    <div class="col-12">
      <h5 class="z-titulo">Pasos con índice y barra + Prime Ng</h5>

      <div class="bs-separador h-30"></div>
    </div>
    <div class="col-12 col-xs-6 col-md-4">
      <div class="z-pasos-indice active">
        <span class="index">1</span>
        <h2 class="z-titulo z-contenido">Proceso Activo</h2>
        <p-progressBar [value]="90" [showValue]="false"></p-progressBar>
      </div>
    </div>
    <div class="col-12 col-xs-6 col-md-3">
      <div class="z-pasos-indice">
        <span class="index">2</span>
        <h2 class="z-titulo z-contenido">Denuncio</h2>
        <p-progressBar [value]="0" [showValue]="false"></p-progressBar>
      </div>
    </div>
    <div class="col-12 col-xs-6 col-md-5">
      <div class="z-pasos-indice active">
        <h2 class="z-titulo z-contenido">
          SOLICITAR REEMBOLSO SALUD: <span>PRESTACIÓN</span>
        </h2>
        <p-progressBar [value]="90" [showValue]="false"></p-progressBar>
      </div>
    </div>
  </div>
</div>

<div class="bs-separador h-30 th-1 lineal"></div>
<div class="z-contenedor">
  <h1 class="z-titulo">Tabla comparativa</h1>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="z-tabla-comparativa">
        <section>
          <div class="cabecera">
            <div class="columna titulo descriptivo no-border"></div>
            <div class="columna titulo">
              <figure>
                <img
                  src="assets/img/svg/icon-zenit-mascota.svg"
                  alt="Icono Tabla"
                />
              </figure>
              <h1>Titulo</h1>
            </div>
            <div class="columna titulo">
              <figure>
                <img
                  src="assets/img/svg/icon-zenit-hogar.svg"
                  alt="Icono Tabla"
                />
              </figure>
              <h1>Titulo 2</h1>
            </div>
            <div class="columna titulo">
              <figure>
                <img
                  src="assets/img/svg/icon-zenit-auto.svg"
                  alt="Icono Tabla"
                />
              </figure>
              <h1>Titulo 3</h1>
            </div>
          </div>
          <div class="contenido">
            <div class="columna subtitulo">
              <h3>Subtítulo</h3>
            </div>
          </div>
          <div class="contenido">
            <div class="columna descriptivo">
              <p>Titular</p>
            </div>
            <div class="columna">
              <span class="min"></span>
            </div>
            <div class="columna">
              <p>Titulo</p>
              <span class="nok"></span>
            </div>
            <div class="columna">
              <span class="ok"></span>
            </div>
          </div>
          <div class="contenido">
            <div class="columna descriptivo">
              <p>Titular</p>
            </div>
            <div class="columna">
              <p>Titulo</p>
              <span class="min"></span>
            </div>
            <div class="columna">
              <span class="ok"></span>
            </div>
            <div class="columna">
              <p>Titulo</p>
              <span class="nok"></span>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="z-tabla-comparativa">
        <section>
          <div class="contenido top">
            <div class="columna descriptivo">
              <p>Titular</p>
            </div>
            <div class="columna">
              <span class="min"></span>
            </div>
            <div class="columna">
              <p>Titulo</p>
              <span class="nok"></span>
            </div>
            <div class="columna">
              <span class="ok"></span>
            </div>
          </div>
          <div class="contenido">
            <div class="columna descriptivo">
              <p>Titular</p>
            </div>
            <div class="columna">
              <p>Titulo</p>
              <span class="min"></span>
            </div>
            <div class="columna">
              <span class="ok"></span>
            </div>
            <div class="columna">
              <p>Titulo</p>
              <span class="nok"></span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<div class="bs-separador h-30 th-1 lineal"></div>
<div class="z-contenedor">
  <h1 class="z-titulo">Planes</h1>
  <div class="row">
    <div class="col-12 col-sm-6 col-lg-4">
      <div class="bloque-planes">
        <p class="z-titulo h3 z-center">Ahorro Solucion 2.0</p>
        <section>
          <p class="z-titulo h3 z-naranja z-center">Deducible UF 3</p>
        </section>
        <p class="z-titulo h1 z-center"><strong>$40.990</strong></p>
        <p class="z-naranja z-center">Venta Online</p>
        <article>
          <div>
            <p>Daños Materiales</p>
            <p><strong>1000 UF</strong></p>
          </div>
          <div>
            <p>Responsabilidad Civil</p>
            <p><strong>500 UF</strong></p>
          </div>
          <div>
            <p>Asistencia Mascotas</p>
            <p><strong>100 UF</strong></p>
          </div>
        </article>
        <a href="#" class="bs-btn bs-btn-primary">Quiero Contratar</a>
        <a href="#" class="bs-btn bs-btn-link">Ver Coberturas</a>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-4">
      <div class="bloque-planes">
        <p class="z-titulo h3 z-center">Ahorro Solucion 2.0</p>
        <section>
          <p class="z-titulo h3 z-naranja z-center">Deducible UF 3</p>
        </section>
        <p class="z-titulo h1 z-center"><strong>$40.990</strong></p>
        <p class="z-naranja z-center">Venta Online</p>
        <article>
          <div>
            <p>Daños Materiales</p>
            <p><strong>1000 UF</strong></p>
          </div>
          <div>
            <p>Responsabilidad Civil</p>
            <p><strong>500 UF</strong></p>
          </div>
          <div>
            <p>Asistencia Mascotas</p>
            <p><strong>100 UF</strong></p>
          </div>
        </article>
        <a href="#" class="bs-btn bs-btn-primary">Quiero Contratar</a>
        <a href="#" class="bs-btn bs-btn-link">Ver Coberturas</a>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-4">
      <div class="bloque-planes">
        <p class="z-titulo h3 z-center">Ahorro Solucion 2.0</p>
        <section>
          <p class="z-titulo h3 z-naranja z-center">Deducible UF 3</p>
        </section>
        <p class="z-titulo h4 z-center z-gris-80 z-stroke">
          <strong>$40.990</strong>
        </p>
        <p class="z-titulo h1 z-center"><strong>$40.990</strong></p>

        <p class="z-naranja z-center">Venta Online</p>
        <article>
          <div>
            <p>Daños Materiales</p>
            <p><strong>1000 UF</strong></p>
          </div>
          <div>
            <p>Responsabilidad Civil</p>
            <p><strong>500 UF</strong></p>
          </div>
          <div>
            <p>Asistencia Mascotas</p>
            <p><strong>100 UF</strong></p>
          </div>
        </article>
        <a href="#" class="bs-btn bs-btn-primary">Quiero Contratar</a>
        <a href="#" class="bs-btn bs-btn-link">Ver Coberturas</a>
      </div>
    </div>
  </div>
</div>

<div class="bs-separador h-100"></div>
<p-dialog
  [(visible)]="modal_1"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="true"
  class="z-modal w-500"
>
  <ng-template pTemplate="header">
    <h2 class="z-titulo z-center">
      <figure class="z-iconos w-96">
        <img src="assets/img/svg/icon-ilus-ko.svg" alt="Icono ilustracion KO" />
      </figure>
      <strong>Ocurrió un error inesperado</strong>
    </h2>
  </ng-template>
  <ng-template pTemplate="content">
    <p class="z-center">Inténtelo más tarde</p>

    <div class="bs-separador h-10"></div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="simple">
      <a href="#" (click)="hide_modal_1()" class="bs-btn bs-btn-primary"
        >Entendido</a
      >
    </div>

    <div class="simple">
      <a href="#" (click)="hide_modal_1()" class="bs-btn bs-btn-link"
        >Lorem Esum</a
      >
      <span class="dummy">|</span>
      <a href="#" (click)="hide_modal_1()" class="bs-btn bs-btn-link"
        >Cancelar</a
      >
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="modal_2"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="true"
  class="z-modal w-700"
>
  <ng-template pTemplate="header">
    <h2 class="z-titulo z-center">
      <strong>Aceptación Términos y condiciones</strong>
    </h2>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="bs-separador h-20"></div>
    <h4 class="z-titulo">
      <strong>Lorem ipsum dolor sit amet consectetur.</strong>
    </h4>
    <div class="bs-separador h-10"></div>
    <p>
      Aliquam rhoncus felis fermentum consequat faucibus egestas. Est risus
      pharetra ac fermentum. Leo in vulputate vestibulum aliquet volutpat arcu
      sed. Ac nunc scelerisque nulla aliquam in gravida accumsan. Sed pharetra
      ipsum vitae sit sollicitudin morbi. Ornare aliquam porttitor vel eu metus
      ligula dictum eget elementum. Sit sed at feugiat sit euismod viverra. Id
      turpis ultrices ut bibendum sed venenatis maecenas. Lorem nec mauris
      malesuada risus praesent. Rhoncus commodo proin odio massa odio. Phasellus
      luctus tincidunt tempor bibendum. Convallis sit commodo sed donec sed.
      Vulputate volutpat id nullam risus ut lacus tincidunt. Adipiscing cursus
      erat ac ac in viverra facilisis. Sed aliquet in gravida velit dictum.
      Facilisi imperdiet volutpat suspendisse morbi turpis. Lectus leo sit
      malesuada praesent. Non fusce tellus id congue iaculis eu sem. Donec
      pulvinar egestas aliquet consequat non id maecenas. Massa penatibus nibh
      nec purus quis molestie. Lectus volutpat felis pretium ornare pulvinar
      elementum.
    </p>

    <div class="bs-separador h-10"></div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="extremos">
      <a
        id="bs-btn-contactenos-header"
        (click)="hide_modal_2()"
        target="_blank"
        class="bs-btn bs-btn-secondary icon"
      >
        <figure>
          <img src="/assets/img/svg/icon-arrow-prev.svg" alt="Ícono atrás" />
        </figure>
        <span> Volver </span>
      </a>
      <a (click)="hide_modal_2()" class="bs-btn bs-btn-primary">Entendido</a>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="modal_3"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="true"
  class="z-modal w-700"
>
  <ng-template pTemplate="header">
    <h2 class="z-titulo z-center">
      <strong>Ejemplo Tarjetas</strong>
    </h2>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="bs-separador h-20"></div>
    <h4 class="z-titulo z-center">
      La tarjeta debe tener enfrente el logo Visa o Mastercard, de lo contrario
      no permitirá la suscripción
    </h4>
    <div class="bs-separador h-10"></div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <figure class="z-imagen">
          <img src="/assets/img/tarjeta-1.jpg" alt="Tarjeta Visa" />
        </figure>
      </div>
      <div class="col-12 col-sm-6">
        <figure class="z-imagen">
          <img src="/assets/img/tarjeta-2.jpg" alt="Tarjeta Masercard" />
        </figure>
      </div>
    </div>

    <div class="bs-separador h-10"></div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="simple">
      <a (click)="hide_modal_3()" class="bs-btn bs-btn-primary">Entendido</a>
    </div>
  </ng-template>
</p-dialog>
