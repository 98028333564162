import { ScrollToBottomDirective } from './../../directives/scroll-to-bottom.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlphaNumericDirective } from '@directives/alpha-numeric.directive';
import { AutoFocusDirective } from '@directives/autofocus.directive';
import { DropdownLoadingDirective } from '@directives/dropdown-loading.directive';
import { LettersOnlyDirective } from '@directives/letters-only.directive';
import { NumbersOnlyDirective } from '@directives/number-only.directive';
import { RutDirective } from '@directives/rut.directive';
import { DisableLinkDirective } from '@directives/disable-link.directive';
import { TrimPipe } from '@pipes/trim.pipe';
import { AutoFocusDropdownDirective } from '@directives/autofocus-dropdown.directive';
import { DotToCommaPipe } from '@pipes/dot-to-comma.pipe';
@NgModule({
  declarations: [
    AlphaNumericDirective,
    AutoFocusDirective,
    DropdownLoadingDirective,
    LettersOnlyDirective,
    NumbersOnlyDirective,
    RutDirective,
    ScrollToBottomDirective,
    DisableLinkDirective,
    TrimPipe,
    DotToCommaPipe,
    AutoFocusDropdownDirective,
  ],
  imports: [CommonModule],
  exports: [
    InputTextModule,
    ProgressBarModule,
    CalendarModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    DialogModule,
    RadioButtonModule,
    TabViewModule,
    TableModule,
    AccordionModule,
    ReactiveFormsModule,
    FormsModule,
    AlphaNumericDirective,
    AutoFocusDirective,
    DropdownLoadingDirective,
    LettersOnlyDirective,
    NumbersOnlyDirective,
    RutDirective,
    ScrollToBottomDirective,
    DisableLinkDirective,
    TrimPipe,
    AutoFocusDropdownDirective,
    DotToCommaPipe,
  ],
})
export class PrimeNGModule {}
