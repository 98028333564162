import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [
  ],

})
export class FooterComponent implements OnInit {
  @Input() tipo:string ='simple';
  public fecha: Date = new Date();
  constructor() { }

  ngOnInit(): void {
  }

}
