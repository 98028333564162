export const isNumber = (value: any): boolean => {
  return !isNaN(value - parseFloat(value));
};

export const isMobile = (): boolean => {
  return window.innerWidth < 768;
};

export const isDesktop = (): boolean => {
  return !isMobile();
};

export const instanceOf = <T>(
  object: any[],
  type: { new (...args: any[]): T }
): object is T[] => {
  return object.every((elem) => elem instanceof type);
};

export const tieneValor = (objeto: any): boolean => {
  return !!!!!!objeto;
};
