import { DialogComponent } from './../components/shared/dialog/dialog.component';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { DialogOptions } from '@components/shared/dialog/dialog.interface';
import { tieneValor } from '@utils/boolean.util';

@Injectable({ providedIn: 'root' })
export class DialogService {
  private componentRef!: ComponentRef<DialogComponent>;
  private dialogOptions!: DialogOptions;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  openDialog(dialogOptions: DialogOptions) {
    if(tieneValor(this.componentRef))
     this.close();
    this.dialogOptions = dialogOptions;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(DialogComponent);

    this.componentRef = componentFactory.create(this.injector);
    this.componentRef.instance.showModal = true;
    this.componentRef.instance.textContent = dialogOptions.textContent;
    this.componentRef.instance.textHeader = dialogOptions.textHeader;
    this.componentRef.instance.showBtnOk = dialogOptions.showBtnOk;
    this.componentRef.instance.showCloseBtn = dialogOptions.showClose;
    this.componentRef.instance.textHandleOk = dialogOptions.textHandleOk;
    this.componentRef.instance.dismissableMask = dialogOptions.dismisable;
    this.componentRef.instance.tipoMensaje = dialogOptions.tipoMensaje;

    this.componentRef.instance.handleOk$.subscribe(() => {
      this.dialogOptions.onClose!.call(null);
    });
    this.componentRef.instance.handleClose$.subscribe(() => {
      //this.dialogOptions.onReject!.call(null);
    });

    this.appRef.attachView(this.componentRef.hostView);

    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  showError(head: string, mensaje: string){

     let g : DialogOptions = {
      textContent : mensaje,
      textHeader : head,
      showBtnOk : false,
      showClose : true,
      textHandleOk : "Cerrar",
      dismisable : true,
      tipoMensaje: 2 // error
     };

     this.openDialog(g);
  }

  showWait(head: string, mensaje: string){

    let g : DialogOptions = {
     textContent : mensaje,
     textHeader : head,
     showBtnOk : false,
     showClose : false,
     textHandleOk : "",
     dismisable : true,
     tipoMensaje: 3 // wait
    };

    this.openDialog(g);
 }

  closeWait(milisegundos: number = 0) {

    setTimeout(()=>{this.close();},milisegundos);    
  }

  close(): void {
    this.componentRef.instance.handleClose();
    //this.dialogOptions.onClose!.call(null);
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
  }
}
