<div>
  <div class="mb-5"></div>
  <div class="error bs-bloque no-padding bs-border-all gris-10 min-h-100">
    <div
      class="d-flex align-items-center justify-content-center flex-column"
      style="padding: 40px"
    >
      <img
        class="mb-5"
        src="assets/img/svg/icon-ilus-ko.svg"
        alt="Icono de Warning"
        style="width: 140px"
      />
      <h3 class="bs-azul mb-3">{{ titulo }}</h3>
      <p class="mb-5">{{ mensaje }}</p>
      <a
        *ngIf="mostrarBoton"
        id="bs-btn-contactenos-header"
        routerLink="/"
        class="bs-btn bs-btn-back"
      >
        <span class="arrow"></span> Volver
      </a>
    </div>
  </div>
</div>
