import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
} from "@angular/animations";

export const fadeAnimation = trigger("fadeAnimation", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("0.5s ease-in", style({ opacity: 1 })),
  ]),
  transition(":leave", [
    style({ opacity: 1 }),
    animate("0.5s ease-in", style({ opacity: 0 })),
  ]),
]);
export const menuAnimation = trigger("menuAnimation", [
  transition(":enter", [
    style({ left: '-100%' }),
    animate("0.2s ease-out", style({ left: 0 })),
  ]),
  transition(":leave", [
    style({ left: 0 }),
    animate("0.2s ease-out", style({ left: '-100%' })),
  ]),
]);
export const listAnimation = trigger("listAnimation", [
  transition("* <=> *", [
    query(
      ":enter",
      [
        style({ opacity: 0 }),
        stagger("0.2s ease-out", animate("0.2s ease-out", style({ opacity: 1 }))),
      ],
      { optional: true }
    ),
    query(":leave", animate("0.2s ease-out", style({ opacity: 0 })), {
      optional: true,
    }),
  ]),
]);
