import { FormControl, FormGroup, Validators } from "@angular/forms"
import { Formulario } from "@interfaces/formulario"
import { PatenteValidator } from "@validators/patente.validator"
import { RutValidator } from "@validators/rut.validator"

export const FormularioNuevo : Formulario  = {
    tieneDescuentoAplicado: false,
    precioForm: {
        id: undefined,
        nombrePlan: undefined,
        precioEnPesos: undefined,
        precioEnUF: undefined,
        seleccionado: undefined,
        deducibleId: undefined,
        planId: undefined,
        descuento: undefined,
        primaNeta: undefined,
        primaBruta: undefined,
        primaBrutaSinTasaInteres: undefined,
        tasaCambioUf: undefined,
    },
    vehiculoForm: new FormGroup({
      datosVehiculo: new FormGroup({
        marcaId: new FormControl('', [Validators.required]),
        modeloId: new FormControl('', [Validators.required]),        
        colorId: new FormControl('', [Validators.required]),
        patente: new FormControl('', [
          Validators.required,
          PatenteValidator.patenteChilenaValidator,
        ]),
        relacionId: new FormControl('1', [Validators.required]),
        usoId: new FormControl('', [Validators.required]),
        estadoId: new FormControl('', [Validators.required]),
        anioId: new FormControl('', [Validators.required])
      }),
      datosMotorChasis: new FormGroup({
        numeroChasis: new FormControl('', [Validators.required]),
        numeroMotor: new FormControl('', [Validators.required]),
        tipoVehiculo: new FormControl(''),
        kilometraje: new FormControl('',[]),
      }),
    }).value,
    aseguradoForm : new FormGroup({
        datosPersonales: new FormGroup({
          rut: new FormControl('', [
            Validators.required,
            RutValidator.validateRut,
          ]),
          apellidos: new FormControl('', [Validators.required]),
          estadoCivilId: new FormControl('1', [Validators.required]),
          generoId: new FormControl('1', [Validators.required]),
          nombres: new FormControl('', [Validators.required]),
          fechaNacimiento: new FormControl('', [Validators.required]),
        }),
        datosUbicacion: new FormGroup({
          regionId: new FormControl('1', [Validators.required]),
          direccion: new FormControl('1', [Validators.required]),
          numeroCasa: new FormControl('1', [Validators.required]),
          comunaId: new FormControl('1', [Validators.required]),
          dptoCasa: new FormControl('qwdwq', [Validators.required]),
        }),
        datosContacto: new FormGroup({
          correo: new FormControl('', [Validators.required]),
          celular: new FormControl('', [Validators.required]),
          telefono: new FormControl('', [Validators.required]),
        }),
      }).value,
    contratanteForm: new FormGroup({
        datosPersonales: new FormGroup({
          nombres: new FormControl('', [Validators.required]),
          estadoCivilId: new FormControl('', [Validators.required]),
          rut: new FormControl('', [Validators.required]),
          generoId: new FormControl('', [Validators.required]),
          apellidos: new FormControl('', [Validators.required]),
        }),
        datosUbicacion: new FormGroup({
          comunaId: new FormControl('', [Validators.required]),
          direccion: new FormControl('', [Validators.required]),
          dptoCasa: new FormControl('', [Validators.required]),
          regionId: new FormControl('', [Validators.required]),
          numeroCasa: new FormControl('', [Validators.required]),
        }),
        detallesPago: new FormGroup({
          formaPagoId: new FormControl('', [Validators.required]),
          numeroCuotasId: new FormControl('', [Validators.required]),
        }),
        datosMotorChasis: new FormGroup({
          numeroChasis: new FormControl('', [Validators.required]),
          numeroMotor: new FormControl('', [Validators.required]),
          tipoVehiculo: new FormControl(''),
          kilometraje: new FormControl('',[]),
        }),
        datosContacto: new FormGroup({
          correo: new FormControl('', [Validators.required]),
          celular: new FormControl('', [Validators.required]),
          telefono: new FormControl('', [Validators.required]),
        }),
      }).value
}