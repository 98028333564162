import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { Step } from './step.interface';
import { StepIndex } from './step-index.interface';
import { StepService } from '@services/step.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css'],
})
export class StepsComponent implements OnInit {
  @Input()
  isMobile = false;

  steps$!: Observable<Step[]>;

  getActiveStep$!: Observable<StepIndex | undefined>;

  constructor(private stepService: StepService) {}

  ngOnInit(): void {
    this.getSteps();
    this.getActiveStep();
  }

  getActiveStep(): void {
    this.getActiveStep$ = this.stepService.getActiveStep$;
  }

  getSteps(): void {
    this.steps$ = this.stepService.getSteps$;
  }
}
