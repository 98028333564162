<div
  id="contenedor"
  style="scroll-behavior: smooth"
  [style.background-image]="
    background_set.estado && background_set.url != ''
      ? 'url(&quot;' + background_set.url + '&quot;)'
      : ''
  "
>
  <router-outlet></router-outlet>
</div>

<app-loading *ngIf="showLoader"></app-loading>
