<div *ngIf="resumen$ | async as r">
  <section class="titular">
    <figure>
      <img src="assets/img/svg/circle-sum.svg" alt="Circle sum" />
    </figure>
    <figcaption>
      <h2>
        Cotización
        <span *ngIf="r.numerocotizacion != ''">#{{ r.numerocotizacion }}</span>
      </h2>
      <h3></h3>
    </figcaption>
  </section>
  <section class="deploy">
    <article
      class="data completed"
      *ngFor="let item of r.resumen; let i = index"
    >
      <span> </span>
      <div class="data-item">
        <button
          class="data-interaction"
          [ngClass]="{ active: item.expanded }"
          (click)="activeDataItem(i)"
        >
          {{ item.title }}
        </button>
        <div class="extra" *ngIf="item.expanded">
          <div
            class="data-module bs-simple-data"
            *ngFor="let extra of item.items"
          >
            <h3>{{ extra.title }}</h3>
            <h4 *ngIf="extra.description != ''">{{ extra.description }}</h4>
            <p *ngIf="extra?.subDescription != ''">
              {{ extra.subDescription }}
            </p>
          </div>
        </div>
      </div>
    </article>

    <article class="data current">
      <span></span>
      <div class="data-item">
        <h2>{{ r.nameCurrentProcess }}</h2>
      </div>
    </article>
  </section>
  <div class="bs-separador h10"></div>
</div>
