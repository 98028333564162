export class CotizacionModel {
  id?: number;
  IdCotizacion?: string;
  RamoId?: number;
  EstadoId?: number;
  UsuarioId?: number;
  PlanId?: number;
  Descuento?: number;
  Comision?: number;
  DescuentoPin?: string;
  Sucursal?: string;
  JsonTarificacion?: string;
  PrimaNeta?: number;
  PrimaBruta?: number;
  Iva?: number;
  Continuidad?: string;
  JsonTarificacionOriginal?: string;
  DiasVigencia?: number;
  EjecutivoId?: number;
  EstadoCotizacion?: number;

  Asegurado?: PersonaModel;
  Ejecutivo?: PersonaModel;
  Contratante?: PersonaModel;
  ConvenioId?: number;
  Vehiculo?: VehiculoModel;
  Deducible?: number;
  FormaPago?: FormaPagoModel;
  TarifaSeleccionada: any;
  IdApp?: number;

  IdGestorOp?: string;
  ramos?: string[];
  numeroCuota0?: number;
  cuotaSimbolica?: boolean;
  conProductosEspecialesIds?: number[];
  mensajePolizaNoDisponible?: string;
  polizaDescargable?: boolean;
  tieneProductosVC:boolean=false;
  tieneProductosVP:boolean=false;
}

export class ActualizarCotizacionModel {
  Deducible?: number;
  PrimaNeta?: number;
  PrimaBruta?: number;
  CotizacionId?: number;
  Descuento?: number;
  PlanId?: number;
  Contratante?: PersonaModel;
  ConvenioId?: number;
}

export class PersonaModel {
  Rut?: string;
  Nombres?: string;
  Apellidos?: string;
  TipoRelacion?: number;
  Celular?: string;
  Comuna?: number;
  Region?: number;
  Correo?: string;
  Direccion?: string;
  Dv?: string;
  FechaNacimiento?: Date;
  Sexo?: string;
}

export class VehiculoModel {
  Anio?: number;
  Chasis?: string;
  Color?: string;
  Estado?: string;
  MarcaAs400?: string;
  ModeloAs400?: string;
  Marca?: string;
  Modelo?: string;
  Motor?: string;
  Patente?: string;
  Uso?: string;
  TipoVehiculo?: string;
  Kilometraje?: string;
  SeValidoPorServicioPT?: boolean;
  SeValidoPorServicioPolizaVigente?: boolean;

  MarcaDesc?: string;
  ModeloDesc?: string;
  IdModeloCorrelativo?: number;
}

export class FormaPagoModel {
  FormaPagoId?: number;
  CantidadCuotas?: number;
  TarifaCuota?: number;
  TarifaCuotaUf?: number;
  MandatoId?: string;
}

export class InfoCotizacionModel {
  id?: number;
  idCotizacion?: string;
}
