import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public deviceType: string = "mobile";
  private background:any= {
    estado:false,
    url:''
  }
  constructor() {
    this.deviceType = this.getDevice(window.innerWidth);
   }
  getDevice(w: number): string {
    let info: string = "mobile";
    if (w < 500) {
      info = "mobile";
    } else if (w >= 500 && w < 900) {
      info = "tablet";
    } else if (w >= 900) {
      info = "desktop";
    }

    return info;
  }

  public set_bg(item:any){
    this.background.estado = item.estado;
    this.background.url = item.url;
  }

  get bg_data() {
    return this.background
  }

}
