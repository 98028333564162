import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[autofocus]',
})
export class AutoFocusDirective {
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.initFocus();
  }

  initFocus(): void {
    setTimeout(() => this.el.nativeElement.focus());
  }
}
