import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { scrollToUp } from '@utils/navigator.util';
import { switchMap, map, filter } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private title: Title,
    private loaderService: LoaderService
  ) {}

  initRouteConfig(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        switchMap((route) => route.data),
        map((data) => data['titulo'])
      )
      .subscribe((dataTitle) => this.title.setTitle(dataTitle));
  }

  initRouteConfigWithLoading() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loaderService.show();
        setTimeout(() => {
          scrollToUp();
        }, 1);
      }
      if (event instanceof NavigationEnd) {
        this.loaderService.hide();
      }
      if (event instanceof NavigationError) {
        this.loaderService.hide();
      }
      if (event instanceof NavigationCancel) {
        this.loaderService.hide();
      }
    });
  }
}
