import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@services/loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
})
export class LoadingComponent implements OnInit {
  textLoading$!: Observable<string>;

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.getTextLoading();
  }

  private getTextLoading(): void {
    this.textLoading$ = this.loaderService.textLoading$;
  }
}
