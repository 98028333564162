import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/providers/shared/shared.service';

@Component({
  selector: 'app-lista-componentes',
  templateUrl: './lista-componentes.component.html',
  styles: []
})
export class ListaComponentesComponent implements OnInit {
  public selector_1: boolean = false;
  public selector_2: boolean = false;
  //
  public fecha: Date = new Date();
  public hora: Date = new Date();
  public fecha2: Date = new Date();
  //
  public mostrar_contrasena: boolean = false;
  //
  public modal_1: boolean = false;
  public modal_2: boolean = false;
  public modal_3: boolean = false;
  public tipo_checkbox: string = '';
  // loader
  public loader: boolean = false;
  public loader_html: boolean = false;
  // dropdowns
  public marcas: any = [
    { nombre: "Mazda" },
    { nombre: "Nissan" },
    { nombre: "Hyundai" },
    { nombre: "sasasa" },
    { nombre: "1212121" },
    { nombre: "2121212" },
    { nombre: "1aaaa" },
    { nombre: "bddd" },
    { nombre: "caaaa" },
    { nombre: "dddd" },
    { nombre: "eeeeee" },
    { nombre: "fff" },
    { nombre: "Masssszda" },
    { nombre: "Nisssssan" },
    { nombre: "Hysssssundai" },
  ];
  public datos: any[] = [
    { id: 1, nombre: "San Antonio" },
    { id: 2, nombre: "Colina" },
    { id: 3, nombre: "Maipú" },
    { id: 4, nombre: "Peñalolen" },
    { id: 5, nombre: "Lampa" },
    { id: 6, nombre: "Ñuñoa" },
  ];
  public tipo_datos: string = '';
  public marcas_selected: string = '';
  //
  public opciones_radiobutton: string = '';
  //
  public device: string = "mobile";
  public backgroundSetup: any = { estado: false, url: '' }
  constructor(private shared: SharedService, private title: Title) {

    this.shared.set_bg(this.backgroundSetup)
  }

  ngOnInit(): void {
    this.title.setTitle('Lista de componentes | Zenit Seguros');
    this.device = this.shared.deviceType;
  }

  show_modal_1() {
    this.modal_1 = true
  }
  hide_modal_1() {
    this.modal_1 = false
  }

  show_modal_2() {
    this.modal_2 = true
  }
  hide_modal_2() {
    this.modal_2 = false
  }

  show_modal_3() {
    this.modal_3 = true
  }
  hide_modal_3() {
    this.modal_3 = false
  }

  metodoMostrarContrasena() {
    this.mostrar_contrasena = this.mostrar_contrasena ? false : true;
  }
  show_loaderhtml() {
    this.loader_html = true;
  }
}
