import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PolizaService {
  constructor(private httpClient: HttpClient) {}

  public async generarPoliza(parametro: string): Promise<any> {
    return this.httpClient
      .post<any>('/Poliza/EmisionSuscripcion', { parametro })
      .toPromise();
  }

  public async generarDirectaPoliza(numeroPoliza: string): Promise<any> {
    return this.httpClient
      .post('/Poliza/EmisionDirecta', { parametro: numeroPoliza })
      .toPromise();
  }

  public async generarMandatoPoliza(
    cotizacionId: string,
    idMandato?: string,
    rutContratante?: string
  ): Promise<any> {
    return this.httpClient
      .post('/Poliza/EmisionMandato', {
        cotizacionId,
        idMandato,
        rutContratante,
      })
      .toPromise();
  }

  public async obtenerArchivoPoliza(numeroPoliza: string): Promise<any> {
    return this.httpClient
      .post(
        '/Poliza/ObtenerPdf',
        { parametro: numeroPoliza.toString() },
        { responseType: 'blob' }
      )
      .toPromise();
  }
}
