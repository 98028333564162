import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicComponent } from './containers/public/public.component';
import { ExitoComponent } from '@components/layouts/exito/exito.component';
import { ErrorComponent } from '@components/layouts/error/error.component';
import { IntencionComponent } from '@components/layouts/intencion/intencion.component';
import { ExitoTransaccionComponent } from '@components/layouts/exito-transaccion/exito-transaccion.component';
import { ConvenioResolver } from '@resolvers/convenio.resolver';
import { AuthResolver } from '@resolvers/auth.resolver';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    resolve:{
      auth: AuthResolver
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../app/components/layouts/home/home.module').then(
            (m) => m.HomeModule
          )
      },
      // {
      //   path: 'exito',
      //   component: ExitoComponent,
      // },
      {
        path: 'error',
        component: ErrorComponent,
      },  
      {
        path: 'intencion',
        component: IntencionComponent
      },
      {
        path: 'exito-transaccion',
        component: ExitoTransaccionComponent
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/error',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
