import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/providers/shared/shared.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-klap-tartejas-datos',
  templateUrl: './klap-tartejas-datos.component.html',
  styles: [
  ]
})
export class KlapTartejasDatosComponent implements OnInit {
  public backgroundSetup: any = { estado: true, url: 'assets/img/zenit_main_background.jpg' }
  public device: string = 'mobile';
  public opciones_radiobutton:string = '';
  constructor(private shared: SharedService, private title: Title) { this.shared.set_bg(this.backgroundSetup)  }
  
  ngOnInit(): void {
    this.device = this.shared.deviceType;
    this.title.setTitle('Contrata y Cotiza en Línea | Zenit Seguros')
  }

}
