// Definimos una interfaz para el tiempo
interface Time {
  minutes?: number; // Propiedad opcional para minutos
  seconds?: number; // Propiedad opcional para segundos
}

/**
 * Convierte un objeto de tiempo a milisegundos.
 * @param time Objeto que contiene minutos y/o segundos.
 * @returns Tiempo en milisegundos.
 */
export const timeToMilliseconds = (time: Time) => {
  const minutesInMilliseconds = (time.minutes || 0) * 60 * 1000;
  const secondsInMilliseconds = (time.seconds || 0) * 1000;

  return minutesInMilliseconds + secondsInMilliseconds;
};
