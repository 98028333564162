<div class="row">
  <div class="col-12 col-lg-6">
    <div class="z-bloque">
      <div
        class="bs-separador"
        [ngClass]="device != 'desktop' ? 'h-0' : 'h-100'"
      ></div>
      <section class="z-informacion-lateral claro">
        <figure>
          <img src="/assets/img/mascota.png" alt="Perro Mascota Zenit" />
        </figure>
        <h1 class="z-titulo z-especial">
          Suscripción de
          <span>Pago Automático</span>
        </h1>
      </section>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="bs-separador h-100" *ngIf="device == 'desktop'"></div>
    <div class="z-bloque background blanco r-4 center-objects">
      <div class="bs-separador h-20"></div>
      <figure class="z-iconos w-96 z-center">
        <img src="assets/img/svg/icon-ilus-ko.svg" alt="Icono ilustracion KO" />
      </figure>
      <div class="bs-separador h-10"></div>
      <h3 class="z-titulo z-center z-naranja">
        <strong>Ocurrió un error inesperado</strong>
      </h3>
      <div class="bs-separador h-30"></div>
      <p class="z-center">Inténtelo más tarde</p>
      <div class="bs-separador h-30"></div>
      <a
        id="bs-btn-contactenos-header"
        href="#"
        target="_blank"
        class="bs-btn bs-btn-secondary icon"
      >
        <figure>
          <img src="/assets/img/svg/icon-arrow-prev.svg" alt="Ícono atrás" />
        </figure>
        <span> Volver </span>
      </a>
      <div class="bs-separador h-10"></div>
    </div>
  </div>
</div>
