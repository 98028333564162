<p-dialog
  [(visible)]="showModal"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="dismissableMask"
  [closable]="showCloseBtn"
  class="bs-modal w-800"
>
  <ng-template pTemplate="header">
    <!-- normal -->
    <h2 class="bs-azul bs-center" *ngIf="tipoMensaje == 1">
      <strong>{{ textHeader }}</strong>
    </h2>
    <!-- error -->
    <h2 class="bs-azul bs-center" *ngIf="tipoMensaje == 2">
      <figure class="bs-iconos w-96">
        <img src="assets/img/svg/icon-ilus-ko.svg" alt="Icono ilustracion KO" />
      </figure>
      <strong>{{ textHeader }}</strong>
    </h2>
    <!-- espera -->
    <h2 class="bs-azul bs-center" *ngIf="tipoMensaje == 3">
      <div class="bs-separador h-20"></div>
      <span class="bs-loader-html w-96"></span>
      <strong>{{ textHeader }}</strong>
    </h2>
  </ng-template>
  <ng-template pTemplate="content">
    <p class="bs-center">
      {{ textContent }}
    </p>

    <div class="bs-separador h-50"></div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="centrar">
      <button
        *ngIf="showBtnOk"
        type="button"
        (click)="handleOk()"
        class="bs-btn bs-btn-primary"
      >
        {{ textHandleOk }}
      </button>
    </div>
    <div class="simple" *ngIf="showCloseBtn">
      <a
        href="#"
        href="javascript:void(0)"
        (click)="handleOk()"
        class="bs-btn bs-btn-primary"
        >{{ textHandleOk }}</a
      >
    </div>
  </ng-template>
</p-dialog>
