<p-dialog
  [visible]="true"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="true"
  [closable]="false"
  [closeOnEscape]="false"
  class="bs-modal w-500"
>
  <ng-template pTemplate="header">
    <h3 class="bs-center bs-azul">
      <div class="bs-separador h-20"></div>
      <span class="bs-loader-html w-96"></span>

      <strong>Cargando los datos del convenio{{ puntitos }}</strong>
    </h3>
  </ng-template>
  <ng-template pTemplate="content">
    <p class="bs-center">Por favor, espere unos segundos</p>

    <div class="bs-separador h-10"></div>
  </ng-template>
  <ng-template pTemplate="footer"> </ng-template>
</p-dialog>
