import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SeguroStore } from '@interfaces/seguro-store.interface';
import { DesplegableResumenService } from '@services/desplegable-resumen.service';
import { PageContentTitleService } from '@services/page-content-title.service';
import { StepService } from '@services/step.service';
import { Store } from '@services/store.service';
import { MEDIO_ENVIO } from '@configs/consts';
import { ActivatedRoute } from '@angular/router';
import { reemplazarTodo } from '@utils/string.util';
import { EstadosCotizacionEnums } from '@enums/estado-cotizacion.enum';
import { CotizacionModel } from '@model/cotizacion-model';
import { DialogService } from '@services/dialog.service';
import { PolizaService } from '@services/poliza.service';
import { tieneValor } from '@utils/boolean.util';
import { ModalGpsComponent } from '../modal-gps/modal-gps.component';
import { GpsResponse } from '@interfaces/gps-response.interface';
import { GpsService } from '@services/gps.service';

@Component({
  selector: 'app-exito-emision-directa',
  templateUrl: './exito-emision-directa.component.html',
  styleUrls: ['./exito-emision-directa.component.css'],
})
export class ExitoEmisionDirectaComponent implements OnInit {
  @ViewChild(ModalGpsComponent, { static: true })
  modalGpsComponent!: ModalGpsComponent;

  medioEnvio: MEDIO_ENVIO = MEDIO_ENVIO.NONE;
  esWhatsapp = false;
  inputValue = new FormControl('', Validators.required);

  ocultarMedioEnvio = false;

  cotizacionId: string = '';

  nombreCliente: string = '';
  marca?: string = '';
  modelo?: string = '';
  color?: string = '';
  anio?: number = 0;
  monto?: string = '';
  montoCuota?: string = '';
  marcaModelo?: string = '';
  urlBase: string = '';

  textInput?: string = '';
  enviandoLink: boolean = false;

  datosPoliza: any;
  descargandoPoliza = false;
  descargandoComprobante = false;
  puntitos: string = '.';
  cargando: boolean = true;
  hayError: boolean = false;
  datosCotizacion?: CotizacionModel;

  gpsResponse!: GpsResponse;
  visualizarGps: boolean = false;

  constructor(
    private stepService: StepService,
    private desplegableResumenService: DesplegableResumenService,
    private pageContentTitleService: PageContentTitleService,
    private store: Store<SeguroStore>,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private polizaService: PolizaService,
    private gpsService: GpsService
  ) {}

  async ngOnInit() {
    this.dialogService.showWait(
      'Emitiendo póliza...',
      'Favor espere un momento'
    );
    this.obtenerEstadoCotizacion();
    this.configStep();
    this.initDesplegableResumen();

    try {
      if (tieneValor(this.datosCotizacion?.FormaPago?.MandatoId)) {
        this.datosPoliza = await this.polizaService.generarMandatoPoliza(
          this.cotizacionId.toString(),
          this.datosCotizacion?.FormaPago?.MandatoId,
          this.datosCotizacion?.Contratante?.Rut
        );
      } else {
        this.datosPoliza = await this.polizaService.generarDirectaPoliza(
          this.cotizacionId.toString()
        );
      }
    } catch (e) {}

    this.dialogService.closeWait(1000);
    this.finalizar();

    if (this.datosPoliza && this.datosPoliza.resultado) {
      this.pageContentTitleService.setContentTitle(
        '¡Póliza Emitida!',
        'La póliza se ha emitido correctamente.'
      );
      this.hayError = false;

      this.cargando = false;
      await this.obtenerDatosGps();
    } else {
      this.pageContentTitleService.setContentTitle(
        'La póliza no ha sido emitida',
        'Hubo un error en el proceso.'
      );
      this.hayError = true;
    }
  }

  private obtenerEstadoCotizacion(): void {
    let formularios = this.store.getState();
    this.urlBase = formularios.urlBase;
    this.nombreCliente =
      formularios.formulario.aseguradoForm.datosPersonales.nombres +
      ' ' +
      formularios.formulario.aseguradoForm.datosPersonales.apellidos;
    this.cotizacionId = formularios.datosCotizacion.IdCotizacion ?? '';
    this.datosCotizacion = formularios.datosCotizacion;
    this.montoCuota =
      '$' + formularios.formulario.precioForm.precioEnPesos?.toLocaleString();
    this.marcaModelo = reemplazarTodo(
      this.desplegableResumenService.obtenerResumen().resumen[1].items[0]
        .subDescription ?? '',
      '\n',
      ''
    );
    this.monto = formularios.formulario.precioForm.precioEnUF?.toString();
  }

  private initDesplegableResumen(): void {
    this.desplegableResumenService.editarNombreProcesoActual('Método de envío');
  }

  private configStep(): void {
    this.stepService.resetAll();
    this.stepService.markAllAsCompletedUntil(5);
    this.stepService.markStepAsActive(5);
  }

  descargarComprobante(): void {
    this.descargandoComprobante = true;
    setTimeout(() => {
      this.descargandoComprobante = false;
    }, 2000);
  }

  async descargarPoliza() {
    this.descargandoPoliza = true;

    try {
      let blob9 = await this.polizaService.obtenerArchivoPoliza(
        this.cotizacionId
      );
      const downloadURL9 = URL.createObjectURL(blob9);

      // Crear un enlace temporal y hacer clic para iniciar la descarga
      const link9 = document.createElement('a');
      link9.href = downloadURL9;
      link9.download = 'Poliza' + this.datosPoliza.numeroPoliza + '.pdf'; // Nombre de archivo sugerido
      link9.click();

      setTimeout(() => {
        URL.revokeObjectURL(downloadURL9);
      }, 2000);
    } catch (e) {
      this.dialogService.closeWait(0);

      setTimeout(() => {
        this.dialogService.showError(
          'Certificado de póliza',
          'hubo un problemas al generar la póliza, por favor reintentelo'
        );
      }, 2000);

      console.log(e);
    }

    setTimeout(() => {
      this.descargandoPoliza = false;
    }, 2000);
  }

  cotizarOtroSeguro(): void {
    window.location.href = this.urlBase;
  }

  finalizar(): void {
    let state9 = this.store.getState();
    let datosCotizacion = state9.datosCotizacion;
    datosCotizacion.EstadoCotizacion = EstadosCotizacionEnums.GENERACION_POLIZA;

    this.store.setState({
      ...state9,
      datosCotizacion: datosCotizacion,
    });
  }

  async obtenerDatosGps() {
    this.gpsResponse = await this.gpsService.obtenerDatosGps(this.cotizacionId);
    this.modalGpsComponent.sanitizarUrl(this.gpsResponse.urlFormulario);
    this.visualizarGps = true;
  }
}
