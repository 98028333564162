import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private isActive$ = new BehaviorSubject<boolean>(false);
  private textLoadingSubject$ = new BehaviorSubject<string>('Cargando');

  constructor() {}

  show(textLoading = 'Cargando'): void {
    this.textLoadingSubject$.next(textLoading);
    this.isActive$.next(true);
  }

  hide(): void {
    return this.isActive$.next(false);
  }

  get status$(): Observable<boolean> {
    return this.isActive$.asObservable();
  }

  get textLoading$(): Observable<string> {
    return this.textLoadingSubject$.asObservable();
  }
}
