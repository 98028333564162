import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/providers/shared/shared.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-klap',
  templateUrl: './klap.component.html',
  styles: [
  ]
})
export class KlapComponent implements OnInit {
  public tipo_checkbox:string='';
  public modal_3: boolean = false;
  public modal_2: boolean = false;
  public modal_1: boolean = false;
  public backgroundSetup: any = { estado: true, url: 'assets/img/zenit_main_background.jpg' }
  public device:string ='mobile';
  constructor(private shared:SharedService, private title:Title) {

    this.shared.set_bg(this.backgroundSetup)
   }

  ngOnInit(): void {
    this.device = this.shared.deviceType;
    this.title.setTitle('Suscripción de pago | Zenit Seguros')
  }
  show_modal_1() {
    this.modal_1 = true
  }
  hide_modal_1() {
    this.modal_1 = false
  }

 
  show_modal_2() {
    this.modal_2 = true
  }
  hide_modal_2() {
    this.modal_2 = false
  }

  show_modal_3() {
    this.modal_3 = true
  }
  hide_modal_3() {
    this.modal_3 = false
  }
}
