import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntencionService } from '@services/intencion.service';
import { encriptarParametrosUrl } from '@utils/encript.util';

@Component({
  selector: 'app-intencion',
  templateUrl: './intencion.component.html',
  styleUrls: ['./intencion.component.css'],
})
export class IntencionComponent implements OnInit {
  puntitos: string = '.';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private intencionService: IntencionService
  ) {}

  async ngOnInit() {
    try {
      setInterval(() => {
        this.crearPuntitos();
      }, 1000);

      let codificado: string = '';

      this.route.queryParams.subscribe((params) => {
        codificado = params.c;
      });

      const cotizacionId = codificado;
      let objeto = await this.intencionService.obtenerUrlIntencion(
        cotizacionId
      );
      window.location.href = objeto.url;
    } catch (e) {
      await this.sleep(1000);
      let url21 =
        '/error?t=' +
        encriptarParametrosUrl('Error') +
        '&m=' +
        encriptarParametrosUrl('No es posible ingresar a esta página');
      this.router.navigateByUrl(url21);
    }
  }

  crearPuntitos() {
    this.puntitos = this.puntitos + '.';

    if (this.puntitos.length > 4) this.puntitos = '';
  }

  sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }
}
