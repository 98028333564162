import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]

}) 
export class HeaderComponent implements OnInit {
  @Input() tipo: string = 'simple';
  public menuPrivadoStatus: boolean = false;
  // simple = para procesos
  // menu = para zona clientes interna
  // corporativo = para zona clientes externa
  constructor() { }

  ngOnInit(): void {
  }
  activarMenuPrivado() {
    this.menuPrivadoStatus = this.menuPrivadoStatus ? false : true;
  }
  activarMenuPrivado_res(e: any) {

    this.menuPrivadoStatus = e;

  }
}
