import { Observable } from 'rxjs';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DesplegableResumen } from './desplegable-resumen.interface';
import { DesplegableResumenService } from '@services/desplegable-resumen.service';

@Component({
  selector: 'app-desplegables-resumen',
  templateUrl: './desplegables-resumen.component.html',
  styleUrls: ['./desplegables-resumen.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DesplegablesResumenComponent implements OnInit {
  resumen$!: Observable<DesplegableResumen>;

  constructor(private desplegableResumen: DesplegableResumenService) {}

  ngOnInit(): void {
    this.obtenerResumen();
  }

  private obtenerResumen(): void {
    this.resumen$ = this.desplegableResumen.getResumen$;
  }

  activeDataItem(index: number) {
    this.desplegableResumen.toggle(index);
  }
}
