import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { encriptarParametrosUrl } from '@utils/encript.util';

@Injectable({
  providedIn: 'root',
})
export class PropuestaService {
  constructor(private httpClient: HttpClient) {}

  async cambiarEstadoCotizacion(cotizacionId: number): Promise<any> {
    let parametro = '/' + encriptarParametrosUrl(cotizacionId.toString());

    return this.httpClient
      .post<any>('/Propuesta' + parametro, null)
      .toPromise();
  }

  async obtenerArchivoPropuesta(cotizacionId: string): Promise<any> {
    let params = new HttpParams().set('id', cotizacionId.toString());
    return this.httpClient
      .get('/Propuesta/PropuestaVentaAsistida/', {
        responseType: 'blob',
        params,
      })
      .toPromise();
  }

  async obtenerIdPropuestaPorIdCotizacion(cotizacionId: string): Promise<any> {
    return this.httpClient
      .get('/Propuesta/ObtenerIdPropuestaPorIdCotizacionVentaAsistida', {
        params: {
          idCotizacion: cotizacionId.toString(),
        },
      })
      .toPromise();
  }
}
