import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numbersOnly]',
})
export class NumbersOnlyDirective {
  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('input', ['$event.target']) onInput(target: HTMLInputElement) {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue.replace(/[^\d]/g, '');
    if (initialValue !== this.el.nativeElement.value) {
      this.ngControl.control?.setValue(this.el.nativeElement.value);
    }
  }
}
