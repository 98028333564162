import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  DesplegableResumen,
  Resumen,
} from '../components/shared/desplegables-resumen/desplegable-resumen.interface';
import { Store } from './store.service';
import { SeguroStore } from '@interfaces/seguro-store.interface';

@Injectable({ providedIn: 'root' })
export class DesplegableResumenService {
  private desplegableResumen: DesplegableResumen = {
    nameCurrentProcess: '',
    resumen: [],
    numerocotizacion: '',
  };

  private subjectResumen$ = new BehaviorSubject<DesplegableResumen>(
    this.desplegableResumen
  );

  constructor(private store: Store<SeguroStore>) {}

  private guardarEstado(): void {
    const state = this.store.getState();
    this.store.setState({
      ...state,
      resumen: this.desplegableResumen,
    });
  }

  init(data: DesplegableResumen): void {
    this.desplegableResumen = data;
    this.subjectResumen$.next(this.desplegableResumen);
    this.guardarEstado();
  }

  agregarResumen(item: Resumen): void {
    this.desplegableResumen.resumen.push(item);
    this.subjectResumen$.next(this.desplegableResumen);
    this.guardarEstado();
  }

  obtenerResumen(): DesplegableResumen {
    const state = this.store.getState();
    this.desplegableResumen = state.resumen || [];
    return this.desplegableResumen;
  }

  eliminarResumen(index: number): void {
    this.desplegableResumen.resumen.splice(index, 1);
    this.subjectResumen$.next(this.desplegableResumen);
    this.guardarEstado();
  }

  editarNombreProcesoActual(name: string): void {
    this.desplegableResumen.nameCurrentProcess = name;
    this.subjectResumen$.next(this.desplegableResumen);
    this.guardarEstado();
  }

  editarNumeroCotizacion(numeroCotizacion: string): void {
    this.desplegableResumen.numerocotizacion = numeroCotizacion;
    this.subjectResumen$.next(this.desplegableResumen);
    this.guardarEstado();
  }

  toggle(index: number): void {
    this.desplegableResumen.resumen[index].expanded =
      !this.desplegableResumen.resumen[index].expanded;
    this.subjectResumen$.next(this.desplegableResumen);
  }

  get getResumen$(): Observable<DesplegableResumen> {
    const state = this.store.getState();
    this.desplegableResumen = state.resumen || [];
    this.subjectResumen$.next(this.desplegableResumen);
    return this.subjectResumen$.asObservable();
  }

  editarCuotaPago(valorCuota: string, valorCuotaUf: string): void {
    const state = this.store.getState();

    this.desplegableResumen = state.resumen || [];
    let r = this.desplegableResumen.resumen.filter(
      (n) => n.title.indexOf('Tarifa') != -1
    )[0];

    r.items[0].description = valorCuotaUf;
    r.items[0].subDescription = valorCuota;
    this.guardarEstado();
  }
}
