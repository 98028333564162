import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService } from '@services/login.service';

@Injectable({ providedIn: 'root' })
export class AuthResolver implements Resolve<Promise<any>> {
  constructor(private loginService: LoginService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.loginService.obtenerTokenResolver();
  }
}