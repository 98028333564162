import { ComunService } from '@services/comun.service';
import { HttpParams } from '@angular/common/http';

export const encryptQueryParams = (urlWithParams: string) => {
  const comunService = new ComunService();
  const urlParts = urlWithParams.split('?');
  if (urlParts.length === 1) {
    // Si la URL no tiene parámetros de consulta, no es necesario encriptar nada
    return null;
  }
  const queryString = urlParts[1];
  const params = new HttpParams({ fromString: queryString });

  const encryptedParams = params.keys().reduce((encrypted, key) => {
    const value = params.get(key) as string;
    const valueToEncrypt = value ? value : '';
    const encryptedValue = btoa(comunService.encriptar(valueToEncrypt));
    return encrypted.set(key, encryptedValue);
  }, new HttpParams());
  const encryptedParamsObj = encryptedParams.keys().reduce((obj: any, key) => {
    const value = encryptedParams.get(key) as string;
    obj[key] = value;
    return obj;
  }, {});

  return encryptedParamsObj;
};


export const encriptarParametrosUrl = (valorEntrada: string) => {
  const comunService = new ComunService();
  let encriptado = comunService.encriptar(valorEntrada);
  return btoa(encriptado);
}


export const desencriptarParametrosUrl = (valorEntrada: string) => {
  const comunService = new ComunService();
  let encriptado = comunService.desencriptar(atob(valorEntrada));
  return encriptado;
}

export const desencriptarBase64_16 = (b64txt: string) => {
  const textEncoder = new TextEncoder();
  const textDecoder = new TextDecoder("utf-16");
  const buff = atob(b64txt);
  const enc = textEncoder.encode(buff);
  const txt = textDecoder.decode(enc);
  return txt;
}