import { Injectable } from '@angular/core';
import { ComunService } from './comun.service';

@Injectable({ providedIn: 'root' })
export class Store<T> {
  private state!: T;
  private STATE = 'STATE';

  constructor(private comunService: ComunService) {}

  private getStateFromSessionStorage(): T {
    const data = sessionStorage.getItem(this.STATE) || 'undefined';
    if (data.toString() !== 'undefined') {
      const stateDesencrypted = this.comunService.desencriptar(data || '');
      return JSON.parse(stateDesencrypted);
    }
    return {} as T;
  }

  setState(state: T): void {
    this.state = state;
    this.saveOnSessionStorage();
  }

  getState(): T {
    return this.state || this.getStateFromSessionStorage() || ({} as T);
  }

  applyPatchState(patch: Partial<T>): void {
    this.state = { ...this.state, ...patch };
    this.saveOnSessionStorage();
  }

  clearState(): void {
    this.state = {} as T;
    this.clearOnSessionStorage();
  }

  private clearOnSessionStorage(): void {
    sessionStorage.removeItem(this.STATE);
  }

  private saveOnSessionStorage(): void {
    if (this.state) {
      const stateEncrypted = this.comunService.encriptar(
        JSON.stringify(this.state)
      );
      sessionStorage.setItem(this.STATE, stateEncrypted);
    }
  }
}
