import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
})
export class DialogComponent implements OnInit, OnDestroy {
  private handleOkSubject$ = new Subject<boolean>();
  private handleCloseSubject$ = new Subject<boolean>();

  showModal = true;
  dismissableMask = true;
  showBtnOk = true;
  showCloseBtn = true;

  textHeader = 'Titulo del dialogo';
  textHandleOk = 'Entendido';
  textContent = '';
  tipoMensaje: number = 1;

  constructor() {}

  ngOnDestroy(): void {
    this.handleOkSubject$.complete();
    this.handleCloseSubject$.complete();
  }

  ngOnInit(): void {}

  handleOk(): void {
    this.showModal = false;
    this.handleOkSubject$.next(true);
  }

  handleClose(): void {
    this.showModal = false;
    this.handleCloseSubject$.next(true);
  }

  get handleOk$(): Observable<boolean> {
    return this.handleOkSubject$.asObservable();
  }

  get handleClose$(): Observable<boolean> {
    return this.handleCloseSubject$.asObservable();
  }
}
