import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[lettersOnly]',
})
export class LettersOnlyDirective {
  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: KeyboardEvent) {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue.replace(
      /[^a-zA-ZñÑáéíóúÁÉÍÓÚ'-\s]/g,
      ''
    );
    if (initialValue !== this.el.nativeElement.value) {
      this.ngControl.control?.setValue(this.el.nativeElement.value);
      event.preventDefault();
    }
  }
}
