import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { desencriptarParametrosUrl } from '@utils/encript.util';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  titulo: string = "Página no encontrada";
  mensaje: string = "Su proceso no pudo ser completado";
  mostrarBoton = true;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const titulo = params.t;
      const mensaje = params.m;

      if(titulo != null && mensaje != null){
        this.titulo = desencriptarParametrosUrl(titulo);
        this.mensaje = desencriptarParametrosUrl(mensaje);
        this.mostrarBoton = false;
      }
    });
  }

}
