import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { menuAnimation } from '../../../animations/animacionComponentes';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styles: [
  ],
  animations: [  menuAnimation]
})
export class MenuComponent implements OnInit {
  @Input() tipo: string = 'flotante';
  @Input() estado: boolean = false;
  @Output() estadoClose = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {

  }
  closeMenu(status:boolean) {

    this.estadoClose.emit(status)

      }


}
