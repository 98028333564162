export const scrollToBottom = (): void => {
  setTimeout(() => {
    scrollTo(0, document.body.scrollHeight);
  }, 100);
};

export const scrollToUp = (): void => {
  setTimeout(() => {
    scrollTo(0, 0);
  }, 100);
};

export const copyToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text);
};
