import { AbstractControl } from '@angular/forms';
import { format, validate } from '@utils/rut.util';

export class RutValidator {
  static validateRut(control: AbstractControl) {
    if (control.value === null || control.value == '') {
      return null;
    }

    const rutFormatted = format(control.value, { dots: true });
    return validate(rutFormatted) ? null : { invalid: 'Rut inválido' };
  }
}
1;
