<div class="card intermedia">
  <div class="col-12 col-md-6 col-lg-4 col-xl-12">
    <div class="bs-tarjeta-detalle">
      <div class="top">
        <h1 data-title="Solicitud">Cotización N° {{ IdCot }}</h1>
        <span class="bs-chip verde" style="background-color: rgb(223, 223, 31)"
          >Link de pago enviado</span
        >
      </div>
      <div class="content">
        <div class="data">
          <div class="data-module">
            <p>Contratante</p>
            <h5>{{ nombreCliente }}</h5>
          </div>
          <div class="data-module">
            <p>Correo</p>
            <h5>{{ correo }}</h5>
          </div>
        </div>
        <div class="data">
          <div class="data-module">
            <p>Valor cuota UF</p>
            <h5>{{ TarifaCuotaUf }}</h5>
          </div>
          <div class="data-module">
            <p>Valor cuota pesos</p>
            <h5>{{ TarifaCuotaPeso | currency : 'CLP' }}</h5>
          </div>
        </div>
      </div>
      <div class="extra">
        <input type="checkbox" id="tarjeta_identificador_2" /><label
          for="tarjeta_identificador_2"
          data-on="Cerrar"
          data-off="Ver más información"
        ></label>
        <div class="expandable-content pt-3">
          <div class="row">
            <div class="col-md-6">
              <div class="bs-accionables ms-4">
                <a (click)="descargarCotizacion()" href="javascript:void(0)"
                  ><figure class="bs-iconos block w-48 ng-star-inserted">
                    <span
                      *ngIf="descargandoCotizacion"
                      class="bs-loader-html w-24"
                      style="top: 10px"
                    ></span>
                    <img
                      *ngIf="!descargandoCotizacion"
                      alt="icono"
                      src="assets/img/svg/icon-color-pdf.svg"
                    />
                  </figure>
                  <span>
                    {{
                      descargandoCotizacion
                        ? 'Descargando cotización...'
                        : 'Descargar cotización'
                    }} </span
                  ><svg
                    width="8"
                    height="13"
                    viewBox="0 0 8 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.30271 0.413446C1.77576 -0.137815 0.922157 -0.137815 0.39521 0.413446C-0.131737 0.964708 -0.131737 1.8577 0.39521 2.40896L4.21021 6.4L0.39521 10.391C-0.131737 10.9423 -0.131737 11.8353 0.39521 12.3866C0.922157 12.9378 1.77576 12.9378 2.30271 12.3866L7.07146 7.39776C7.5984 6.8465 7.5984 5.9535 7.07146 5.40224L2.30271 0.413446Z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div class="col-md-6" style="text-align: end">
              <div class="bs-accionables me-4">
                <a (click)="descargarPropuesta()" href="javascript:void(0)"
                  ><figure class="bs-iconos block w-48 ng-star-inserted">
                    <span
                      *ngIf="descargandoPropuesta"
                      class="bs-loader-html w-24"
                      style="top: 10px"
                    ></span>
                    <img
                      *ngIf="!descargandoPropuesta"
                      alt="icono"
                      src="assets/img/svg/icon-color-pdf.svg"
                    />
                  </figure>
                  <span>
                    {{
                      descargandoPropuesta
                        ? 'Descargando propuesta...'
                        : 'Descargar propuesta'
                    }} </span
                  ><svg
                    width="8"
                    height="13"
                    viewBox="0 0 8 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.30271 0.413446C1.77576 -0.137815 0.922157 -0.137815 0.39521 0.413446C-0.131737 0.964708 -0.131737 1.8577 0.39521 2.40896L4.21021 6.4L0.39521 10.391C-0.131737 10.9423 -0.131737 11.8353 0.39521 12.3866C0.922157 12.9378 1.77576 12.9378 2.30271 12.3866L7.07146 7.39776C7.5984 6.8465 7.5984 5.9535 7.07146 5.40224L2.30271 0.413446Z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-between">
  <a
    (click)="cotizarOtroSeguro()"
    href="javascript:void(0)"
    class="bs-btn bs-btn-secondary"
    >Cotizar otro seguro</a
  >
  <!-- <a (click)="finalizar()" class="bs-btn bs-btn-primary">Finalizar</a> -->
</div>
