import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({ selector: '[autoFocusDropdown]' })
export class AutoFocusDropdownDirective {
  constructor(private el: ElementRef) {}

  @Input()
  autoFocusDropdown = true;

  @HostListener('click', ['$event'])
  onClick(): void {
    if (!this.autoFocusDropdown) return;
    const element = this.el.nativeElement as HTMLElement;
    const dropdown = element.querySelector(
      '.p-dropdown-filter.p-inputtext.p-component'
    ) as HTMLElement;

    if (dropdown) {
      dropdown.focus();
    }
  }
}