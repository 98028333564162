import { Directive, ElementRef, HostListener } from '@angular/core';
import { format } from '@utils/rut.util';

@Directive({ selector: '[formatRut]' })
export class RutDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  onKeydown(value: string) {
    if (value) {
      const rutFormatted = format(value, { dots: true });
      this.el.nativeElement.value = rutFormatted === '-' ? '' : rutFormatted;
    }
  }
}
