import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IntencionService {
  constructor(private httpClient: HttpClient) {}

  async enviarLink(cotizacionId: string, correo: string): Promise<void> {
    this.httpClient
      .post(
        '/intencion/EnviarLink',
        { parametro: cotizacionId.toString(), correo },
        { responseType: 'text' }
      )
      .toPromise();
  }

  async obtenerUrlIntencion(cotizacionId: string): Promise<any> {
    return this.httpClient
      .post<any>('/intencion/GenerarIntencion', {
        parametro: cotizacionId.toString(),
      })
      .toPromise();
  }
}
