import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-coberturas-flexibles',
  templateUrl: './coberturas-flexibles.component.html',
  styleUrls: ['./coberturas-flexibles.component.css'],
})
export class CoberturasFlexiblesComponent {
  @Input() planesOriginales: any[] = [];
  @Input() coberturas: any[] = [];
  @Output() coberturasChange = new EventEmitter<any[]>();
  @Output() recotizar: EventEmitter<void> = new EventEmitter();

  seleccionarOpc(
    id: any,
    seleccionado: boolean,
    planId: any,
    idGrupo: any = null,
    multiOp: boolean = true
  ) {
    if (!multiOp) {
      this.cotizar();
      return;
    }

    if (idGrupo) {
      for (let i = 0; i < this.coberturas.length; i++) {
        let cobertura = this.coberturas[i];

        for (let j = 0; j < cobertura.planes.length; j++) {
          let plan = cobertura.planes[j];

          if (plan.id == planId) {
            for (let k = 0; k < plan.opciones.length; k++) {
              let op = plan.opciones[k];

              if (op.idGrupo == idGrupo) op.seleccionado = false;
            }
          }
        }
      }
    }

    for (let i = 0; i < this.coberturas.length; i++) {
      let cobertura = this.coberturas[i];

      for (let j = 0; j < cobertura.planes.length; j++) {
        let plan = cobertura.planes[j];

        if (plan.id == planId) {
          for (let k = 0; k < plan.opciones.length; k++) {
            let op = plan.opciones[k];

            if (op.id == id) {
              op.seleccionado = !seleccionado;
            }
          }
        }
      }
    }

    this.cotizar();
  }

  cotizar() {
    this.coberturasChange.emit(this.coberturas);
    this.recotizar.emit();
  }
}
