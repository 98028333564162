import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class ComunService {
  constructor() {}

  encriptar(valorEntrada: string, cryptoKey: string = ''): string {
    let bytesLlave = cryptoKey != '' ? CryptoJS.enc.Utf8.parse(cryptoKey) : CryptoJS.enc.Utf8.parse(environment.cryptoKey);
    let bytesIV = CryptoJS.enc.Utf8.parse('0123456789123456');
    let encriptado = CryptoJS.AES.encrypt(valorEntrada, bytesLlave, {
      keySize: 16,
      iv: bytesIV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encriptado.toString();
  }

  desencriptar(valorEncriptado: string, cryptoKey: string = ''): string {
    let bytesLlave =  cryptoKey != '' ? CryptoJS.enc.Utf8.parse(cryptoKey) : CryptoJS.enc.Utf8.parse(environment.cryptoKey);
    let bytesIV = CryptoJS.enc.Utf8.parse('0123456789123456');
    return CryptoJS.AES.decrypt(valorEncriptado, bytesLlave, {
      keySize: 16,
      iv: bytesIV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
  }
}
