import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/providers/shared/shared.service';

@Component({
  selector: 'app-klap-error',
  templateUrl: './klap-error.component.html',
  styles: [
  ]
})
export class KlapErrorComponent implements OnInit {
  public device: string = 'mobile';
  public backgroundSetup: any = { estado: true, url: 'assets/img/zenit_main_background.jpg' }

  constructor(private shared:SharedService) { 
    this.shared.set_bg(this.backgroundSetup)
  }

  ngOnInit(): void {
    this.device = this.shared.deviceType;
  }

}
