import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[alphaNumeric]' })
export class AlphaNumericDirective {
  @Input()
  acceptWhiteSpace = false;

  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: KeyboardEvent) {
    const initialValue = this.el.nativeElement.value;
    const regex = this.acceptWhiteSpace ? /[^a-zA-Z0-9 ]*/g : /[^a-zA-Z0-9]*/g;
    this.el.nativeElement.value = initialValue.replace(regex, '');

    if (initialValue !== this.el.nativeElement.value) {
      this.ngControl.control?.setValue(this.el.nativeElement.value);
      event.stopPropagation();
    }
  }
}
