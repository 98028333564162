import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-gps',
  templateUrl: './modal-gps.component.html',
  styleUrls: ['./modal-gps.component.css'],
})
export class ModalGpsComponent {
  tituloModal = 'Solicitud GPS: Ley 21.170';
  mostrarModal: boolean = true;
  urlIframe: SafeResourceUrl | null = null;

  constructor(private sanitizer: DomSanitizer) {}


  sanitizarUrl(url: string): any {
    this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  cerrarModal(): void {
    this.mostrarModal = false;
  }
}
