import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[scrollToBottom]',
})
export class ScrollToBottomDirective {
  @Output() scrolledToBottom = new EventEmitter();

  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event.target'])
  onScroll(target: HTMLElement) {
    const scrollThreshold = 100;
    const scrollHeight = target.scrollHeight;
    const scrollTop = target.scrollTop;
    const clientHeight = target.clientHeight;

    if (scrollHeight - scrollTop - clientHeight < scrollThreshold) {
      this.scrolledToBottom.emit();
    }
  }
}
