import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/providers/shared/shared.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-klap-tartejas',
  templateUrl: './klap-tartejas.component.html',
  styles: [
  ]
})
export class KlapTartejasComponent implements OnInit {
  public device: string = "mobile";
  public backgroundSetup: any = { estado: true, url: 'assets/img/zenit_main_background.jpg' }
  public opciones_tarjetas:string = 'tarjeta_1';
  constructor(private shared: SharedService, private title: Title) { this.shared.set_bg(this.backgroundSetup) }
  
  ngOnInit(): void {
    this.device = this.shared.deviceType;
    this.title.setTitle('Tarjetas Inscritas | Zenit Seguros')
  }

}
