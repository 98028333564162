import { ComponentsModule } from './components/components.module';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContainersModule } from './containers/containers.module';
import { PrimeNGModule } from './components/prime-ng/prime-ng.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeEs from '@angular/common/locales/es-CL';
import { registerLocaleData } from '@angular/common';
import { SharedService } from './providers/shared/shared.service';
import { PublicComponent } from '@containers/public/public.component';
import { ErrorHandlerInterceptor } from '@interceptors/error-handler.interceptor';
import { HttpTokenInterceptor } from '@interceptors/http-token.interceptor';
import { ApiPrefixInterceptor } from '@interceptors/api-prefix.interceptor';
import { RetryInterceptor } from '@interceptors/retry.interceptor';
import { TimeoutInterceptor } from '@interceptors/timeout.interceptor';

registerLocaleData(localeEs);

@NgModule({
  declarations: [AppComponent, PublicComponent],
  imports: [
    PrimeNGModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ContainersModule,
    ComponentsModule,
  ],
  providers: [
    SharedService,

    { provide: LOCALE_ID, useValue: 'es-CL' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
