<div class="row">
  <div class="col-12 col-lg-7">
    <div class="z-bloque">
      <div
        class="bs-separador"
        [ngClass]="device != 'desktop' ? 'h-0' : 'h-100'"
      ></div>
      <section class="z-informacion-lateral claro">
        <figure>
          <img src="/assets/img/mascota.png" alt="Perro Mascota Zenit" />
        </figure>
        <h1 class="z-titulo z-especial">
          Suscripción y pagos Zenit Seguros
          <span>Contrata y Cotiza en Línea</span>
        </h1>
      </section>
    </div>
  </div>
  <div class="col-12 col-lg-5">
    <div class="bs-separador h-30" *ngIf="device == 'desktop'"></div>
    <div class="z-bloque background blanco r-4">
      <h4 class="z-titulo naranja z-up z-negrita">
        <strong>Resumen de la transacción</strong>
      </h4>
      <div class="bs-separador h-10 lineal th-1"></div>
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="bs-simple-data">
            <h3>Transacción</h3>
            <p>Z_r8a_0004</p>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="bs-simple-data">
            <h3>Fecha Transacción</h3>
            <p>17/12/2023</p>
          </div>
        </div>

        <div class="col-6 col-md-4">
          <div class="bs-simple-data naranja">
            <h3>Prima mensual a pagar *</h3>
            <p>$123.123</p>
          </div>
        </div>
      </div>
      <div class="bs-separador h-10"></div>
      <small>* Monto UF al día de hoy $35.123,12</small>
    </div>
    <div class="bs-separador h-20"></div>
    <div class="z-bloque background blanco r-4 no-padding">
      <article class="background naranja">
        <h4 class="z-titulo blanco z-center">
          <strong
            >Tienes 3 minutos para realizar el ingreso de los datos</strong
          >
        </h4>
      </article>
      <article class="m-t-10 m-b-10">
        <h4 class="z-titulo naranja z-up z-negrita">
          <strong>Ingresa los datos de tu tarjeta</strong>
        </h4>
        <p>Visa o Mastercard</p>
        <div class="bs-separador h-10 lineal th-1"></div>
        <form>
          <div class="row">
            <div class="col-12">
              <div class="bs-forms">
                <p class="z-label">tipo de tarjeta</p>

                <p-radioButton
                  name="Op1"
                  value="Op1"
                  [(ngModel)]="opciones_radiobutton"
                  inputId="radioButton_1"
                  label="Tarjeta de crédito"
                ></p-radioButton>
                <p-radioButton
                  name="Op1"
                  value="Op2"
                  [(ngModel)]="opciones_radiobutton"
                  inputId="radioButton_2"
                  label="Tarjeta de débito"
                ></p-radioButton>
              </div>
            </div>
            <div class="col-12">
              <label for="form-1" class="bs-forms">
                <p class="z-label">Nº de Tarjeta</p>
                <!-- INPUT ASOCIADO A PRIME NG -->
                <input
                  type="text"
                  placeholder="Ej. 1111 2222 3333 4444"
                  pInputText
                  id="form-1"
                />
              </label>
            </div>
            <div class="col-12 col-md-6">
              <label for="form-1" class="bs-forms">
                <p class="z-label">Fecha vencimiento</p>
                <!-- INPUT ASOCIADO A PRIME NG -->
                <input
                  type="text"
                  placeholder="Ej.  05/2025"
                  pInputText
                  id="form-1"
                />
              </label>
            </div>
            <div class="col-12 col-md-6">
              <label for="form-1" class="bs-forms">
                <p class="z-label">CVV</p>
                <!-- INPUT ASOCIADO A PRIME NG -->
                <input
                  type="password"
                  placeholder="***"
                  pInputText
                  id="form-1"
                  maxlength="5"
                />
              </label>
            </div>
          </div>
        </form>
        <div class="bs-separador h-10"></div>
        <div class="z-bloque center-objects no-padding">
          <a href="#" class="bs-btn bs-btn-primary">Pagar</a>
        </div>
      </article>
      <article class="background naranja-20">
        <h5 class="z-titulo z-center">
          No cierre esta ventana hasta terminar proceso de pago
        </h5>
      </article>
    </div>
  </div>
</div>
