import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent
  implements OnInit, ControlValueAccessor, Validator
{
  @Input()
  inputId = '';

  @Input()
  extAllowed = '';

  @Input()
  className = '';

  fileName!: string;

  onChangeCallback: any = (_: any) => {};
  onTouchedCallback: any = () => {};

  private fileList: File[] = [];
  private isDisabled!: boolean;
  private control!: AbstractControl;

  constructor() {}

  ngOnInit(): void {}

  private setFileName(): void {
    if (this.fileList.length > 0) {
      this.fileName = this.fileList[0].name;
    } else {
      this.fileName = '';
    }
  }

  writeValue(fileList: File[]): void {
    if (fileList !== this.fileList) {
      this.fileList = fileList;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = false;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.control = control;
    return control.valid ? null : { invalid: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    fn();
  }

  getFileFromInput(event: any) {
    const files = event.target.files as FileList;
    this.getNameFiles(files);
  }

  getNameFiles(files: FileList): void {
    const fileList: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i) as File;
      fileList.push(file);
    }

    this.value = [];
    this.value = fileList;
    this.onTouchedCallback();
    this.validValidators();
  }

  validValidators(): void {}

  get value(): File[] {
    return this.fileList;
  }

  set value(fileList: File[]) {
    if (fileList !== this.fileList) {
      this.fileList = fileList;
      this.setFileName();
      this.onChangeCallback(fileList);
    }
  }

  get limitSize() {
    return this.control.getError('limitSize');
  }

  get extInvalid() {
    return this.control.getError('extInvalid');
  }
}
